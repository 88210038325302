//
//
//
//
//
//
//
//

import axios from "axios";
export default {
    name: "competitions",
    props: {
        url: {
            type: String,
            required: true
        },
        server: {
            type: String,
            required: true
        }
    },
    components: {

    },
    data() {
        return {
            cmData: '',
            competitionExist: false
        }
    },
    mounted() {
        axios.get('https://app.loading.express/competitions?url='+this.url).then(({data}) => {
            if(data && data.data && data.data.length > 0) {
                this.cmData = data.data;
                this.competitionExist = true;
            }
        }).catch(error => {
            console.log('crux dosen\'t collected: '+error);
        });
    }
}
