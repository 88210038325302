//
//
//
//
//
//
//

import FormButton from "~/components/FormButton";

export default {
    name: "Cookies",
    components: {
        FormButton
    },
    data() {
        return {
            show: false,
            details: false
        }
    },
    methods: {
        close() {
            this.show = false;
            this.$cookies.set("confirm-cookies", 1, {maxAge: 365 * 24 * 3600})
        }
    },
    mounted() {
        if (process.browser && !navigator.userAgent.includes("Chrome-Lighthouse") && !this.$cookies.get("confirm-cookies")) this.show = true
    }
}
