//
//
//
//
//
//
//
//
//
//
//
//

import FormButton from "~/components/FormButton";

export default {
    name: "blog",
    components: {
        FormButton
    }
}
