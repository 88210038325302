//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ToolTip from "~/components/ToolTip";

export default {
    name: "TheStat",
    components: {
        ToolTip
    },
    props: {
        title: String,
        category_title: String,
        category_label: String,
        without_pages: Boolean
    },
    data() {
        try {
            document.addEventListener("click", () => this.open_select = false)
        } catch (e) {}
        return {
            open_select: false,
            more_filters: false
        }
    },
    computed: {
        rating_button_label() {
            return this.$store.state.page_data.rating.rating_button ? this.$store.state.page_data.rating.rating_button : false //Общий-рейтинг
        },
        select_label() {
            if (!this.$store.state.list_filter.length) return "Все";
            return `${this.category_label ? this.category_label : "Выбрано"}: ${this.$store.state.list_filter.length}`
        },
        list_filter: {
            get() {
                return [].concat(this.$store.state.list_filter)
            },
            set(value) {
                this.$store.commit("set_list_filter", value)
            }
        },
        has_second_page() {
            return !!this.$store.state.data.filter(i => i.data && i.data.second).length
        },
        currentValueTitle() {
            switch (this.$store.state.current) {
                case "rating":
                    return this.rating_button_label;
                case "fully_loaded_time":
                    return "Скорость загрузки в секундах";
                case "page_bytes":
                    return "Вес страницы";
                case "google_pagespeed":
                    return "Google PageSpeed Insights";
                case "page_elements":
                    return "Число запросов";
            }
        },
        alphabet_list() {
            let list = this.$store.state.data.map(item => item.info.title);
            list = list.sort();
            return list
        },
        list() {
            let list = this.$store.state.data.map(item => Object.assign({}, item));
            if (this.$store.state.page === "main") {
                list = list.map(item => Object.assign(item, {
                    data: item.data.main,
                    timestamp: new Date(item.data.timestamp)
                }))
            } else {
                list = list.map(item => Object.assign(item, {
                    data: item.data.second,
                    url: item.second_url,
                    timestamp: new Date(item.data.timestamp)
                }))
            }

            list = list.filter(i => i.data);

            list = list.map(item => {
                item.id = item.data[this.$store.state.mode].id;
                item.data = this.$store.state.mode === "desktop" ? item.data.desktop : item.data.mobile;
                return item
            });
            list = list.sort((a, b) => a.data.rating > b.data.rating ? -1 : (a.data.rating < b.data.rating ? 1 : 0));
            list = list.map(item => Object.assign(item, {
                url: `http${item.data.ssl ? "s" : ""}://${item.url}`,
                ssl: item.data.ssl
            }));
            list = list.map(item => Object.assign(item, {data: +item.data[this.$store.state.current]}));
            list = list.sort((a, b) => {
                let sort = a.data < b.data ? 1 : (a.data > b.data ? -1 : 0);
                switch (this.$store.state.current) {
                    case "html_load_time":
                    case "fully_loaded_time":
                    case "page_bytes":
                    case "page_elements":
                        sort *= -1
                }
                return sort
            });

            list = list.map((item, index) => Object.assign(item, {index: index + 1}));

            if (this.$store.state.list_filter.length) {
                list = list.filter(item => this.$store.state.list_filter.includes(item.info.title))
            }

            list = list.sort((a, b) => {
                let sort = 0;
                switch (this.$store.state.sort) {
                    case "value":
                        sort = a.data > b.data ? 1 : (a.data < b.data ? -1 : 0);
                        break;
                    case "title":
                        sort = a.info.title > b.info.title ? 1 : (a.info.title < b.info.title ? -1 : 0)
                }
                switch (this.$store.state.current) {
                    case "html_load_time":
                    case "fully_loaded_time":
                    case "page_bytes":
                    case "page_elements":
                        sort *= -1
                }
                return sort * this.$store.state.order
            });

            switch (this.$store.state.current) {
                case "html_load_time":
                case "fully_loaded_time":
                    list = list.map(item => Object.assign(item, {value: `${+(item.data / 1000).toFixed(3)} сек.`}));
                    break;
                case "rating":
                case "page_elements":
                    list = list.map(item => Object.assign(item, {value: +item.data}));
                    break;
                case "google_pagespeed":
                    list = list.map(item => Object.assign(item, {value: Math.round(item.data * 100)}));
                    break;
                case "page_bytes":
                    list = list.map(item => {
                        let size = +item.data;
                        let size_unit = "B";
                        if (size > 1024) {
                            size = (size / 1024).toFixed(2);
                            size_unit = "KB";
                        }
                        if (+size > 1024) {
                            size = (size / 1024).toFixed(2);
                            size_unit = "MB";
                        }
                        return Object.assign(item, {value: `${+size} ${size_unit}`})
                    });
                    break;
            }

            const values = list.map(item => item.data);
            const min = Math.min(...values);
            const max = Math.max(...values);

            list = list.map(item => {
                let size = +item.data;
                let rate = size;
                switch (this.$store.state.current) {
                    case "rating":
                        size = (size - min) / (max - min);
                        rate = size;
                        break;
                    case "html_load_time":
                    case "fully_loaded_time":
                    case "page_bytes":
                    case "page_elements":
                        size = (size - min) / (max - min);
                        rate = 1 - size;
                }
                return Object.assign(item, {rate, size})
            });
            return list
        },
        info_text() {
            const data = {
                rating: this.$store.state.page_data.rating.rating_description,
                fully_loaded_time: "Скорость загрузки - время, за которое страница будет полностью загружена на устройстве. Чем меньше это время - тем лучше. Идеальное время - менее 3 секунд.",
                page_bytes: "Вес страницы - объем всех подключаемых ресурсов на странице. Чем меньше объем, тем быстрее происходит загрузка при медленном интернете. Кроме того, чем больше объем, тем больше денег с вас возьмут в роуминге. Идеальный вес - до 1MB.",
                page_elements: "Число запросов - количество обращений к серверу, которое необходимо для отрисовки страницы. Чем меньше запросов - тем меньше ожидание. Идеально - до 100 запросов.",
                google_pagespeed: "Pagespeed - оценка Google скорости загрузки сайтов по 100-бальной шкале. Чем ближе к 100 тем лучше. Идеально - 100 баллов."
            };
            return data[this.$store.state.current] || false
        }
    },
    methods: {
        setMode(mode="desktop") {
            /** @namespace this.$store.commit **/
            if (this.$store.state.current !== "rating") this.$store.commit(`set_mode_${mode}`)
        },
        setPage(page="main") {
            if (this.$store.state.current !== "rating") this.$store.commit(`set_page_${page}`)
        },
        selectItem(value) {
            const list = [].concat(this.list_filter);
            const index = list.indexOf(value);
            if (index < 0) {
                list.push(value)
            } else {
                list.splice(index, 1);
            }
            this.list_filter = list
        },
        changeSort(value) {
            this.$store.commit("set_sort", value);
            if (value === this.$store.state.sort) {
                this.$store.commit("set_order", this.$store.state.order > 0 ? -1 : 1)
            } else {
                this.$store.commit("set_order", -1)
            }
        }
    }
}
