// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/compare.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".compareWidget{padding:10px 0 10px 50px;background:#fff url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 10px/30px;border-radius:3px;box-shadow:0 20px 50px rgba(0,36,51,.3);position:fixed;top:70px;right:20px;width:1px;height:50px;z-index:10;display:flex;flex-direction:column;justify-content:center;text-decoration:none;color:#335766;overflow:hidden;transition:all .15s linear;white-space:nowrap}.compareWidget:hover{opacity:1;width:150px}.compareWidget:hover .notice{opacity:0}.compareWidget .title{font-size:14px}.compareWidget .notice{position:absolute;top:3px;left:27px;background:#d52055;border-radius:50%;width:20px;height:20px;opacity:1;transition:all .15s linear;color:#fff;display:flex;justify-content:center;align-items:center;font-size:14px}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
