//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ResumePopup from "~/components/ResumePopup";
import LazyImg from "~/components/LazyImg";
export default {
    name: "about",
    components: {
        ResumePopup,
        LazyImg
    },
    data() {
        return {resume_popup: false};
    }
}
