//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TheHeaderStat from "~/components/TheHeaderStat"
import FormButton from "~/components/FormButton"
import TheStatText from "~/components/TheStatText"
import TheStat from "~/components/TheStat"
import TheFooter from "~/components/TheFooter"
import ToolTip from "~/components/ToolTip";
import elementOffset from "~/plugins/elementOffset";
import OrderForm from "~/components/OrderForm";
import TheChampions from "~/components/TheChampions";
import OrderPopup from "~/components/OrderPopup";
import MonitoringPopup from "~/components/MonitoringPopup";
import SubscribePopup from "~/components/SubscribePopup";
import TheRatingsList from '@/components/TheRatingsList.vue'
import topPanel from "~/components/topPanel";

export default {
    name: "rating_page",
    components: {
        TheRatingsList,
        SubscribePopup,
        OrderPopup,
        MonitoringPopup,
        TheChampions,
        OrderForm,
        ToolTip,
        TheHeaderStat,
        FormButton,
        TheStatText,
        TheStat,
        TheFooter,
        topPanel,
    },
    httpHeaders: () => ({
        'Last-Modified': new Date().toGMTString()
    }),
    async validate({store, route}) {
        try {
            await store.dispatch("GET_PAGE", route.params.slug);
            await store.dispatch("GET_STAT", store.state.page_data.rating.category);
            await store.dispatch("GET_RATINGS_LIST", store.state.page_data._id);
            return true
        } catch (e) {
            return false
        }
    },
    head() {
        return {
            title: this.page_data.meta.title,
            meta: [
                {hid: "description", name: "description", content: this.page_data.meta.description},
                {hid: "og:locale", property: "og:locale", content: "ru"},
                {hid: "og:title", property: "og:title", content: this.page_data.meta.title},
                {hid: "og:description", property: "og:description", content: this.page_data.meta.description},
                {hid: "og:image", property: "og:image", content: this.static_path(this.page_data.meta.og_image)},
            ]
        }
    },
    computed: {
        page_data() {
            return this.$store.state.page_data
        }
    },
    methods: {
        go_to_anchor(anchor) {
            if (this.$refs[anchor]) {
                const offset = elementOffset(this.$refs[anchor].$el);
                window.scrollTo(0, offset.top)
            }
        }
    },
    data() {
        return {
            order_popup: false,
            monitoring_popup: false
        }
    }
}
