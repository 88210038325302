//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import callback from "~/components/callback";
// import elementOffset from "~/plugins/elementOffset";
export default {
    name: "FAQ",
    components: {
    },
}
