//
//
//
//
//

export default {
    name: "FormButton",
    props: {
        type: {
            type: String,
            validate: value => ["button", "a", "div", "n-link"].includes(value),
            default: "button"
        },
        to: String,
        href: String,
        target: String
    }
}
