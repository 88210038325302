//
//
//
//
//
//
//

export default {
    name: "TheHeaderStat",
  components: {
  },
  props: {
        title: String
    }
}
