//
//
//
//
//
//
//

import TheFooter from '~/components/TheFooter'
import TheStatText from '~/components/TheStatText'
import topPanel from "@/components/topPanel";

export default {
  name: 'privacy',
  components: {
    TheStatText,
    TheFooter,
    topPanel
  },
  httpHeaders: () => ({
    'Last-Modified': new Date().toGMTString()
  }),
  head() {
    return {
      title: "Политика конфиденциальности LOADING.express",
      meta: [
        {hid: "description", name: "description", content: "Политика конфиденциальности по работе с персональными данными в LOADING.express"},
        {hid: "og:locale", property: "og:locale", content: "ru"},
        {hid: "og:title", property: "og:title", content: "Политика конфиденциальности LOADING.express"},
        {hid: "og:description", property: "og:description", content: "Политика конфиденциальности по работе с персональными данными в LOADING.express"},
        {hid: "og:image", property: "og:image", content: "https://loading.express/og-ru.jpg"},
      ]
    }
  },
  data() {
    return {
      text: `
    <h1>Политика обработки персональных данных</h1>
    <div class="right">Политика вступает в силу 19 декабря 2023 года.<br><a href="/privacy-1">Предыдущая версия политики</a>.</div>

    <h2>1. Что регулирует этот документ?</h2>
    <p>Этот документ определяет правила обработки персональных данных пользователей сайта и сервисов, принадлежащих ООО “Лоудинг.ру”. ООО “Лоудинг.ру” является оператором в понимании Федерального закона от 27 июля 2006 г. № 152-ФЗ "О персональных данных" и политика разработана в соответствии с его положениями.</p>
    <p style="padding:10px;border: 2px solid black">Прочтите политику перед тем, как начать использовать сайт или сервисы оператора. Если у вас возникнут вопросы, в том числе по поводу отзыва согласия на обработку персональных данных, напишите нам на help@loading.express.</p>

    <h2>2. Чьи и какие данные обрабатываются?</h2>
    <p>Оператор обрабатывает данные всех пользователей, которые взаимодействуют с сайтом и сервисами оператора. Оператор собирает персональные данные через формы сайта и сервисов, которые заполняет пользователь, с помощью средств коммуникации, в том числе по телефону, по электронной почте и автоматически с помощью технологий, веб-протоколов и файлов “cookie”.</p>
    <p>При использовании сайта и сервисов оператор может получить от пользователя следующую информацию:</p>
    <ul>
      <li>имя и, если пользователь укажет, фамилию и отчество пользователя; </li>
      <li>номер телефона;</li>
      <li>адрес электронной почты;</li>
    </ul>
    <p>Также в некоторых полях может потребоваться ввести адрес принадлежащего пользователю сайта.</p>
    <p>При заключении договора с оператором на оказание им услуг для пользователя могут потребоваться паспортные данные и адрес пользователя.</p>
    <p>Оператор исходит из того, что пользователь предоставляет достоверные данные и поддерживает их в актуальном состоянии. Оператор не проверяет дееспособность пользователя. Персональные данные, которые обрабатывает оператор, не являются биометрическими и не относятся к специальным категориям персональных данных.</p>
    <p>Дополнительно в процессе использования сайта и сервисов оператора последнему могут стать известны:</p>
    <ul>
      <li>данные о технических устройствах пользователя: IP-адрес, вид операционной системы, тип браузера, географическое положение, поставщик услуг сети Интернет;</li>
      <li>сведения о поведении пользователя на сайте и сервисах: дата, время и количество посещений, сведения о посещенных страницах, о переходе с других ресурсов, о направленных заявках;</li>
      <li>информация, автоматически получаемая при доступе к сайту и сервисам с использованием файлов <a href="/cookies">cookies</a>. Cookies необходимы для правильной работы и предоставления полного функционала сайта и сервисов</li>
    </ul>
    <p>На сайте и сервисах может быть размещено программное обеспечение третьих лиц, в результате чего они могут получать обезличенные данные, передаваемые в автоматическом режиме. К такому программному обеспечению могут относиться счетчики сервисов Яндекс Метрика и Google Analytics, встроенные блоки YouTube и другие системы сбора обезличенных данных. Пользователь может самостоятельно ограничить сбор таких данных, изменив настройки конфиденциальности в браузере.</p>
    <h2>3. Зачем и как обрабатываются персональные данные</h2>
    <p>Оператор обрабатывает данные пользователя для достижения прямых и косвенных целей.</p>
    <h3>Прямые цели:</h3>
    <ul>
      <li>идентификация пользователя;</li>
      <li>поддержание связи с пользователем;</li>
      <li>исполнение обязательств по заключенным договорам;</li>
      <li>предоставление консультаций по вопросам, касающимся оказываемых услуг;</li>
      <li>техническая поддержка пользователя;</li>
      <li>продвижение услуг оператора путем осуществления прямых контактов с пользователем;</li>
      <li>предоставление пользователю справочной информации, в том числе о предстоящих мероприятиях, предоставление материалов по результатам проведенных мероприятий.</li>
    </ul>
    <h3>Косвенные цели:</h3>
    <ul>
      <li>обеспечение работоспособности сайта и сервисов;</li>
      <li>повышение удобства использования сайта и сервисов;</li>
      <li>проведение статистических исследований.</li>
    </ul>
    <p>Оператор осуществляет обработку данных с использованием средств автоматизации или без использования таких средств путем сбора, записи, систематизации, накопления, хранения, уточнения (обновления, изменения), извлечения, использования, передачи (предоставления, доступа), блокирования, удаления, уничтожения.</p>
    <p>Оператор вправе передавать данные третьим лицам либо поручать обработку персональных данных третьим лицам, если это будет необходимо для достижения цели их обработки в соответствии законом и политикой, а также в случаях, предусмотренных действующим законодательством Российской Федерации о персональных данных.</p>
    <p>Данные обрабатываются оператором до момента достижения цели их обработки, если иные сроки не предусмотрены действующим законодательством Российской Федерации о персональных данных, либо до момента отзыва пользователем своего согласия на обработку его персональных данных.</p>
    <h2>4. Как защищаются персональные данные</h2>
    <p>Оператор хранит полученные персональные данные на защищенных электронных носителях с использованием баз данных, находящихся на территории РФ, а также использует иные необходимые технические меры, которые предъявляются Федеральным законом от 27.07.2006 № 152-ФЗ “О персональных данных”.</p>
    <p>Оператор не разглашает полученные персональные данные без письменного разрешения пользователя, не осуществляет их продажу, обмен, опубликование либо иное разглашение данных пользователя. При этом персональные данные пользователя могут быть переданы уполномоченным органам государственной власти Российской Федерации по основаниям и в порядке, установленным законодательством.</p>
    <h2>5. Согласие на обработку персональных данных. Отзыв согласия.</h2>
    <p>Политика считается принятой пользователем, а согласие на обработку предоставленным оператору при выполнении одного или всех из следующих условий:</p>
    <ul>
      <li>использования сайта и сервисов оператора;</li>
      <li>заполнения полей в формах, предусмотренных на сайте и сервисах оператора.</li>
    </ul>
    <p>Используя сайт и сервисы, а также заполняя поля форм пользователь подтверждает, что ему известны его права и обязанности, предусмотренные действующим законодательством Российской Федерации о персональных данных, в частности, право на доступ к своим персональным данным, на отзыв своего согласия, удаление и уточнение данных.</p>
    <p>Если пользователь не намерен предоставлять персональные данные или не принимает условия политики, он должен прекратить использование сайта и сервисов оператора.</p>
    <p>По требованию пользователя, а также при выявлении недостоверных персональных данных или неправомерных действий, оператор прекращает обработку персональных данных пользователя и удаляет такие данные.</p>
    <p>Требование пользователя об отзыве согласия на обработку или удаление его данных направляется оператору в письменном виде по юридическому адресу. Требование должно содержать информацию, позволяющую идентифицировать пользователя, в том числе его номер телефона и адрес электронной почты. Оператор рассматривает и направляет ответ на поступившее требование в течение тридцати календарных дней с момента поступления требования.</p>
    <h2>6. Заключительные положения</h2>
    <p>Оператор не может гарантировать бесперебойный и безотказный доступ к сайту и сервисам и ни при каких условиях не несет ответственность за возможные убытки, возникшие у пользователя в связи с их использованием.</p>
    <p>Оператор не несет ответственности за:</p>
    <ul>
      <li>нарушение конфиденциальности или использование третьими лицами персональных данных, которые самостоятельно распространил или представил пользователь;</li>
      <li>действия третьих лиц, получивших доступ к персональным данным пользователя в результате его собственных действий;</li>
      <li>возможное нецелевое использование персональных данных пользователя, произошедшее из-за технических неполадок в программном обеспечении, серверах или компьютерных сетях, находящихся вне контроля оператора.</li>
    </ul>
    <p>Оператор вправе вносить изменения в политику. Новая редакция политики вступает в силу с момента ее публикации. Если пользователь продолжит использовать сайт и сервисы после вступления в силу новой редакции политики, это будет означать, что он согласился с условиями новой редакции.</p>
    <h2>7. Реквизиты оператора</h2>
    <p>ООО “Лоудинг.ру”<br>
    ИНН: 9705211423<br>
    КПП: 770501001<br>
    Юридический адрес: 115093, Г.Москва, вн.тер.г. Муниципальный Округ Замоскворечье, ул Большая Серпуховская, д. 44<br>
    Генеральный директор: Махметхажиев Алексей Леонидович</p>
        `
    }
  }
}
