//
//
//
//
//
//
//
//
//

import TheFooter from "~/components/TheFooter";
import OrderForm from "~/components/OrderForm";
import topPanel from "~/components/topPanel";
import chromeEx from '~/components/chromeEx';
import compareList from '~/components/compareList';

export default {
    name: "index",
    components: {
        OrderForm,
        TheFooter,
        topPanel,
        chromeEx,
        compareList
    },
    httpHeaders: () => ({
        'Last-Modified': new Date().toGMTString()
    }),
    head() {
        return {
            title: 'Сравнение скорости сайтов на LOADING.express',
            meta: [
                {hid: "description", name: "description", content: 'Сравни скорость своего сайта с конкурентами. Стрница сравнения скорости сайтов.'},
                {hid: "og:locale", property: "og:locale", content: "ru"},
                {hid: "og:title", property: "og:title", content: 'Сравнение скорости сайтов на LOADING.express'},
                {hid: "og:description", property: "og:description", content: 'Сравни скорость своего сайта с конкурентами. Стрница сравнения скорости сайтов.'},
                {hid: "og:image", property: "og:image", content: "https://loading.express/og-ru.jpg"},
                {hid: "twitter:image", property: "twitter:image", content: "https://loading.express/og-ru.jpg"},
                {hid: "twitter:title", property: "twitter:title", content: "Сравнение скорости сайтов на LOADING.express"},
                {hid: "twitter:description", property: "twitter:description", content: "Сравни скорость своего сайта с конкурентами. Стрница сравнения скорости сайтов."},
            ],
            link: [
                {rel: "alternate", hreflang: "x-default", href: "https://loading.express/compare"},
                {rel: "alternate", hreflang: "ru-ru", href: "https://loading.express/compare"},
                {rel: "alternate", hreflang: "en-us", href: "https://load.express/compare"}

            ]
        }
    }
}
