//
//
//
//
//
//
//

import TheFooter from '~/components/TheFooter'
import TheStatText from '~/components/TheStatText'
import topPanel from "@/components/topPanel";

export default {
  name: 'privacy',
  components: {
    TheStatText,
    TheFooter,
    topPanel
  },
  httpHeaders: () => ({
    'Last-Modified': new Date().toGMTString()
  }),
  head() {
    return {
      title: "Политика конфиденциальности LOADING.express",
      meta: [
        {hid: "description", name: "description", content: "Политика конфиденциальности по работе с персональными данными в LOADING.express"},
        {hid: "og:locale", property: "og:locale", content: "ru"},
        {hid: "og:title", property: "og:title", content: "Политика конфиденциальности LOADING.express"},
        {hid: "og:description", property: "og:description", content: "Политика конфиденциальности по работе с персональными данными в LOADING.express"},
        {hid: "og:image", property: "og:image", content: "https://loading.express/og-ru.jpg"},
      ]
    }
  },
  data() {
    return {
      text: `
    <h1>Политика конфиденциальности</h1>
    <div class="right">Редакция № 2 от 12.12.2023 г.</div>

    <p>Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) действует в отношении всей информации, которую ООО "ЛОУДИНГ.РУ" (ИНН: 9705211423, ОГРН: 1237700756017) может получить о Пользователе во время использования сайтов:</p>
    <ul>
        <li><a href="https://loading.express/" rel="nofollow noopener" target="_blank">loading.express</a></li>
        <li><a href="https://webvitals.ru/" rel="nofollow noopener" target="_blank">webvitals.ru</a></li>
        <li><a href="https://load.express/" rel="nofollow noopener" target="_blank">load.express</a></li>
    </ul>
    <p>во время использования сайтов, расположенных на поддоменах указанных интернет-ресурсов.</p>

    <h2>1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</h2>
    <p>1.1. В настоящей Политике конфиденциальности используются следующие термины:</p>
    <p>1.1.1. “Администрация сайта”, “Оператор” - ООО "ЛОУДИНГ.РУ" (ИНН 9705211423, ОГРН 1237700756017), который организует и (или) осуществляет обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</p>
    <p>1.1.2. “Персональные данные” - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).</p>
    <p>1.1.3. “Обработка персональных данных” - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
    <p>1.1.4. “Конфиденциальность персональных данных” - обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.</p>
    <p>1.1.5. “Пользователь сайта” (далее ‑ Пользователь) – лицо, имеющее доступ к сайту, посредством сети Интернет и использующее сайт.</p>
    <p>1.1.6. “Cookies” — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.</p>
    <p>1.1.7. “IP-адрес” — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.</p>

    <h2>2. ОБЩИЕ ПОЛОЖЕНИЯ</h2>
    <p>2.1. Использование Пользователем сайта означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя.</p>
    <p>2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование сайта.</p>
    <p>2.3. Настоящая Политика конфиденциальности применяется только в отношении обозначенных в ней сайтов. ИП Махметхажиев А. Л. не контролирует и не несет ответственности за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на указанных сайтах.</p>
    <p>2.4. Администрация сайта не проверяет достоверность персональных данных, предоставляемых Пользователем сайта.</p>

    <h2>3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</h2>
    <p>3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации сайта.</p>
    <p>3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путём заполнения регистрационной формы на сайте, формы заказа обратного звонка, формы оформления заказа товаров или услуг и т.д., и могут включать в себя следующую информацию:</p>
    <p>3.2.1. фамилию, имя, отчество Пользователя;</p>
    <p>3.2.2. контактный телефон Пользователя;</p>
    <p>3.2.3. адрес электронной почты (e-mail);</p>
    <p>3.2.4. паспортные данные Пользователя.</p>
    <p>3.3. Отключение cookies может повлечь невозможность доступа к частям сайта, требующим авторизации.</p>
    <p>3.4. Любая иная персональная информация не оговоренная выше (история покупок, используемые браузеры и операционные системы, номера платежных карт и т.д.) подлежит надежному хранению и нераспространению, за исключением случаев, предусмотренных в п.п. 5.2. и 5.3. настоящей Политики конфиденциальности.</p>

    <h2>4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</h2>
    <p>4.1. Персональные данные Пользователя Администрация сайта может использовать в целях:</p>
    <p>4.1.1. Идентификации Пользователя, зарегистрированного на сайте.</p>
    <p>4.1.2. Предоставления Пользователю доступа к персонализированным ресурсам сайта.</p>
    <p>4.1.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования сайта, оказания услуг, обработки запросов и заявок от Пользователя.</p>
    <p>4.1.4. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.</p>
    <p>4.1.5. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.</p>
    <p>4.1.6. Создания учетной записи, в том числе для совершения покупок или заказа услуг.</p>
    <p>4.1.7. Уведомления Пользователя сайта о состоянии заказа.</p>
    <p>4.1.8. Обработки и получения платежей, оспаривания платежа.</p>
    <p>4.1.9. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием сайта.</p>
    <p>4.1.10. Осуществления рекламной деятельности с согласия Пользователя.</p>

    <h2>5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</h2>
    <p>5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.</p>
    <p>5.2. Пользователь соглашается с тем, что Администрация сайта вправе передавать персональные данные третьим лицам, в частности операторам электросвязи, исключительно в целях выполнения заказа Пользователя, оформленного на сайте.</p>
    <p>5.3. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Российской Федерации только по основаниям и в порядке, установленным законодательством Российской Федерации.</p>
    <p>5.4. При утрате или разглашении персональных данных Администрация сайта информирует Пользователя об утрате или разглашении персональных данных.</p>
    <p>5.5. Администрация сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.</p>

    <h2>6. ОБЯЗАТЕЛЬСТВА СТОРОН</h2>
    <p>6.1. Пользователь обязан:</p>
    <p>6.1.1. Предоставить информацию о персональных данных, необходимую для пользования сайтом.</p>
    <p>6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.</p>
    <p>6.2. Администрация сайта обязана:</p>
    <p>6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.</p>
    <p>6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением п.п. 5.2. и 5.3. настоящей Политики Конфиденциальности.</p>
    <p>6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядка, обычно используемого для защиты такого рода информации в существующем деловом обороте.</p>
    <p>6.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.</p>

    <h2>7. ОТВЕТСТВЕННОСТЬ СТОРОН</h2>
    <p>7.1. Администрация сайта, не исполнившая свои обязательства, несёт ответственность за убытки, понесенные Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Российской Федерации, за исключением случаев, предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей Политики Конфиденциальности.</p>
    <p>7.2. В случае утраты или разглашения Конфиденциальной информации Администрация сайта не несёт ответственность, если данная конфиденциальная информация:</p>
    <p>7.2.1. Стала публичным достоянием до её утраты или разглашения.</p>
    <p>7.2.2. Была получена от третьей стороны до момента её получения Администрацией сайта.</p>
    <p>7.2.3. Была разглашена с согласия Пользователя.</p>

    <h2>8. РАЗРЕШЕНИЕ СПОРОВ</h2>
    <p>8.1. Претензионный порядок является обязательным. Спор может быть передан на разрешение суда после принятия сторонами мер по досудебному урегулированию по истечении тридцати календарных дней со дня направления претензии.</p>
    <p>8.2. Споры из договора разрешаются в судебном порядке по месту нахождения ответчика.</p>

    <h2>9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h2>
    <p>9.1. Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.</p>
    <p>9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики конфиденциальности.</p>
    <p>9.3. Действующая Политика конфиденциальности размещена на странице по адресу «https://loading.express/privacy».</p>
        `
    }
  }
}
