//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormButton from "~/components/FormButton"
import axios from "axios"
import config from "~/config"
import ThePopup from "~/components/ThePopup";
import elementOffset from "~/plugins/elementOffset";
import Fz from "~/components/fz";
import FormWrapper from "~/components/FormWrapper";
import callback from "~/components/callback";
import SubscribePopup from "~/components/SubscribePopup";
import coreWebVitals from "~/components/coreWebVitals";
import competitions from "~/components/competitions";
import compareAdd from "~/components/compareAdd"

const API_URL = `${config.API}/api`;
const MAIL_URL = `${config.API}/mail`;

/** @namespace text.analysis **/
/** @namespace text.html **/
/** @namespace text.sec **/
/** @namespace text.score_gzip **/
/** @namespace text.full **/
/** @namespace text.ttfb **/
/** @namespace text.text.perfect **/
/** @namespace text.text.good **/
/** @namespace text.text.bad **/

export default {
    name: "ScanScreen",
    computed: {
        other_site(){
            return this.$store.state.scanner.site.indexOf('loading.express') > -1;
        },
        show_email() {
            return this.$store.state.cookie.day_scans > 1 && !this.$store.state.cookie.already_subscribed
        },

        full_size_string() {
            let full_size = +this.$store.state.scanner.result.size;
            let size_unit = "B";
            if (full_size > 1024) {
                full_size = (full_size / 1024).toFixed(2);
                size_unit = "KB";
            }
            if (full_size > 1024) {
                full_size = (full_size / 1024).toFixed(2);
                size_unit = "MB";
            }
            return `${ full_size } ${ size_unit }`
        },
        offscreen_image_string() {
            let full_size = +this.$store.state.scanner.result.offscreen_image;
            let size_unit = "B";
            if (full_size > 1024) {
                full_size = (full_size / 1024).toFixed(2);
                size_unit = "KB";
            }
            if (full_size > 1024) {
                full_size = (full_size / 1024).toFixed(2);
                size_unit = "MB";
            }
            return `${ full_size } ${ size_unit }`
        },
        cache_save_string() {
            let full_size = +this.$store.state.scanner.result.cache_save;
            let size_unit = "B";
            if (full_size > 1024) {
                full_size = (full_size / 1024).toFixed(2);
                size_unit = "KB";
            }
            if (full_size > 1024) {
                full_size = (full_size / 1024).toFixed(2);
                size_unit = "MB";
            }
            return `${ full_size } ${ size_unit }`
        },
        compress_save_string() {
            let full_size = +this.$store.state.scanner.result.compress_save;
            let size_unit = "B";
            if (full_size > 1024) {
                full_size = (full_size / 1024).toFixed(2);
                size_unit = "KB";
            }
            if (full_size > 1024) {
                full_size = (full_size / 1024).toFixed(2);
                size_unit = "MB";
            }
            return `${ full_size } ${ size_unit }`
        },
        webp_save_string() {
            let full_size = +this.$store.state.scanner.result.webp_save;
            let size_unit = "B";
            if (full_size > 1024) {
                full_size = (full_size / 1024).toFixed(2);
                size_unit = "KB";
            }
            if (full_size > 1024) {
                full_size = (full_size / 1024).toFixed(2);
                size_unit = "MB";
            }
            return `${ full_size } ${ size_unit }`
        },
        screenshot() {
            return this.$store.state.scanner.result.screenshot;
        },
        domContentLoaded() {
            return (this.$store.state.scanner.result.domContentLoaded / 1000).toFixed(1)
        },
        full_time() {
            return (this.$store.state.scanner.result.load / 1000).toFixed(1)
        },
        rate() {
            return this.grade(this.full_time, 3, 12)
        },
        score_compress() {
            return Math.floor(this.$store.state.scanner.result.score_compress / 10)
        },
        score_zip() {
            return Math.floor(this.$store.state.scanner.result.score_zip / 10)
        },
        score_cache() {
            return Math.floor(this.$store.state.scanner.result.score_cache_acc / 10)
        },
        testDate() {
            return new Date(this.$store.state.scanner.result.testTime).toLocaleString("ru");
        },
        ttfb_rate() {
            return this.grade(this.$store.state.scanner.result.TTFB, 400, 2000)
        },
        EvaluateScript_rate() {
            return this.grade(this.$store.state.scanner.result.EvaluateScript, 2000, 10000)
        },
        FCP_rate() {
            return this.grade(this.$store.state.scanner.result.FCP, 2000, 8000)
        },
        FID_rate() {
            return this.grade(this.$store.state.scanner.result.FID, 300, 2000)
        },
        onload_rate() {
            return this.grade(this.$store.state.scanner.result.domContentLoaded, 3000, 8000)
        },
        LastInteractive_rate() {
            return this.grade(this.$store.state.scanner.result.LastInteractive, 3000, 10000)
        },
        Requests_rate() {
            return this.grade(this.$store.state.scanner.result.requests, 99, 200)
        },
        Connects_rate() {
            return this.grade(this.$store.state.scanner.result.connects.length, 2, 15)
        },
        Size_rate() {
            return this.grade(this.$store.state.scanner.result.size, 1148576, 10485760)
        },
        total_rate() {
            const minRate = Math.min(
              this.rate,
              this.ttfb_rate,
              this.EvaluateScript_rate,
              this.LastInteractive_rate,
              this.FCP_rate,
              this.Size_rate,
              this.Requests_rate,
              this.onload_rate,
              this.FID_rate,
              this.Connects_rate
            );
            const avgRate = (this.rate + this.ttfb_rate + this.EvaluateScript_rate + this.LastInteractive_rate + this.FCP_rate + this.Size_rate + this.Requests_rate + this.onload_rate + this.FID_rate + this.Connects_rate) / 10;
            return Math.round((avgRate + minRate) / 2)
        },
        site_domain() {
            return this.$store.state.scanner.site.replace("http://", "").replace("https://", "").split("/")[0]
        },
        site_url() {
            return new URL('/',this.$store.state.scanner.site);
        },
        site_full_url() {
            return `http://${this.$store.state.scanner.site.replace("http://", "").replace("https://", "")}`
        }
    },
    components: {
        Fz,
        ThePopup,
        FormButton,
        FormWrapper,
        callback,
        SubscribePopup,
        coreWebVitals,
        competitions,
        compareAdd
    },
    watch: {
        "$store.state.scanner.status"(value, oldValue) {
            if (value !== oldValue && value === "start") this.start()
        }
    },
    methods: {
        // oldScreenshot(event) {
        //     event.target.src = this.$store.state.scanner.result.screenshot.replace("http://", "https://");
        // },
        getExternalResources() {
            let domains_group = [];
            let currentDomain = new URL(this.$store.state.scanner.site);
            for(let item of this.$store.state.scanner.result.requestsItem){
                if(item.url.indexOf('data:') < 0){
                    let urln = new URL(item.url);
                    if(urln.hostname != currentDomain.hostname){
                        if(!domains_group[urln.hostname]) {
                            domains_group[urln.hostname] = [];
                        }
                        domains_group[urln.hostname].push(item);
                    }
                } else {
                    if(!domains_group['inline']) {
                        domains_group['inline'] = [];
                    }
                    domains_group['inline'].push(item);
                }
            }
            return domains_group;
        },
        getUrlPath(url) {
            if(!url.startsWith('data:')){
                let urln = new URL(url);
                if(urln.pathname.length < 40){
                    return urln.pathname;
                } else {
                    return urln.pathname.substr(0,20) + '...' + urln.pathname.substr(urln.pathname.length - 20,urln.pathname.length);
                }
            } else {
                return 'Встроенный ' + url.replace(/data:(.+?)[;,].+/, '$1');
            }
        },
        size_human(size) {
            let full_size = +size;
            let size_unit = "B";
            if (full_size > 1024) {
                full_size = (full_size / 1024).toFixed(2);
                size_unit = "KB";
            }
            if (full_size > 1024) {
                full_size = (full_size / 1024).toFixed(2);
                size_unit = "MB";
            }
            return `${ full_size } ${ size_unit }`
        },
        copyLink() {
            let linkToCopy = document.querySelector('#copyLink');
            linkToCopy.setAttribute('type', 'text');
            linkToCopy.select();
            try {
                document.execCommand('copy');
                var linkText = document.querySelector('.clipboard');
                linkText.classList.add("copied");
                setTimeout(function(){
                    linkText.classList.remove("copied")
                },1000);
            } catch (err) {
            }
            linkToCopy.setAttribute('type', 'hidden');
            window.getSelection().removeAllRanges();
            this.$sendGoal("copy-audit-link");
        },
        go_to_scan() {
            window.scrollTo(0, elementOffset(this.$el).top)
        },
        getMessengerLinks() {
            this.getAudit = !this.getAudit;
            this.$sendGoal("open-messanger-link");
        },
        moreTarget(){
            this.moreDetail =! this.moreDetail;
            this.$sendGoal("show-me-more");
        },
        clickMessengerLink() {
            this.$sendGoal("click-to-messenger");
        },
        clickMailAudit() {
            this.subscr = true;
            this.$sendGoal("click-email-audit");
            us_client.fetchAjaxForm();
        },
        clickSEOAudit() {
          this.seo = true;
        },
        clickMailAuditDown() {
            this.subscr = true;
            this.$sendGoal("click-email-audit-down");
            us_client.fetchAjaxForm();
        },
        clickPhoneAudit() {
            this.callback = true;
            this.$sendGoal("click-phone-audit");
            us_client.fetchAjaxForm();
        },
        decQueue() {
            if(this.queue > 1) {
                this.queue--;
            }
            this.$store.commit("set_scanner_queue", this.queue);
        },
        get_test_result(test_id, server) {
            this.getAudit = false;
            this.queue = 0;
            if (test_id) {
                this.$router.push({query: {test_id, server}});
            }
            if (this.$store.state.scanner.status) {
                return axios.post(API_URL, {id: test_id, server: server}).then(({data}) => {
                    if (data.error) {
                        if (data.error === 404) return this.close();
                        this.$store.commit("set_scanner_status", "error");
                        if (data.url) this.$store.commit("set_scanner_site", data.url.replace(/^https?:\/\//, ""));
                        this.$cookies.remove("current_site_test");
                        clearInterval(this.textInterval);
                        clearInterval(this.horrorInterval);
                        clearInterval(this.percentsInterval);
                        this.float.text = "Не удалось завершить сканирование";
                        // axios.post(MAIL_URL, {
                        //     site: this.$store.state.scanner.site,
                        //     email: this.$store.state.scanner.email,
                        //     client_id: this.$cookies.get("utmstat_client_id"),
                        //     mail_subject: 'Не удалось завершить сканирование ' + this.$store.state.scanner.site + ' #ERROR',
                        //     comment: 'Не удалось завершить сканирование ' + this.$store.state.scanner.site
                        // });
                        return false;
                    }
                    if (data.wait) {
                        if (data.url) this.$store.commit("set_scanner_site", data.url.replace(/^https?:\/\//, ""));
                        this.$store.commit("set_scanner_status", "wait");
                        // if(this.$store.state.cookie.already_subscribed) {
                            this.$store.commit("set_scanner_queue", data.queue + 1);
                            this.testTimeout = setTimeout(this.get_test_result, 1000, test_id, server);
                        // } else {
                        //     this.queue = Math.floor(Math.random() * (50 - 30)) + 30;
                        //     setInterval(this.decQueue, 2000);
                            // this.testTimeout = setTimeout(this.get_test_result, 60000, test_id, server);
                        // }
                        return true;
                    }
                    if (data.progress) {
                        // if(!this.makeScreen) {
                        //     this.makeScreen = true;
                        //     let surl = this.$store.state.scanner.site;
                        //     axios.post('https://screen.loading.express/', {
                        //         url: surl,
                        //         id: test_id
                        //     }).then(({data}) => {
                        //
                        //     });
                        // }
                        if (data.url) this.$store.commit("set_scanner_site", data.url.replace(/^https?:\/\//, ""));
                        this.$store.commit("set_scanner_status", "progress");
                        if (!this.startTime) {
                            this.startTime = Date.now();
                            this.float.percents = 0;
                            this.percentsInterval = setInterval(() => {
                                let delta = Math.round((Date.now() - this.startTime) / 1000);
                                if (delta <= 10) {
                                    this.float.percents = Math.round(5 * delta);
                                } else {
                                    if(delta <= 59) {
                                        this.float.percents = delta + 40;
                                    } else {
                                        this.float.percents = 99;
                                    }
                                }
                            }, 100);
                        }
                        this.testTimeout = setTimeout(this.get_test_result, 1000, test_id, server);
                        return true;
                    }
                    if (data.result) {
                        if (data.url) this.$store.commit("set_scanner_site", data.url);
                        clearInterval(this.textInterval);
                        clearInterval(this.percentsInterval);
                        axios.get(`https://loading.express/bot/?url=${data.url}`);

                        for (let input of this.$el.getElementsByTagName("input")) {
                            input.setAttribute("autocorrect", "off")
                        }

                        // this.percentsInterval = setInterval(() => {
                        //     if (this.startTime && this.float.percents < 100) {
                        //         this.float.percents++
                        //     } else {
                                clearInterval(this.percentsInterval);
                                this.float.text = "Завершено!";
                                this.$store.commit("set_scanner_status", "result");
                                this.float.startTime = 0;
                        //     }
                        // }, 10);
                        if(data.result.requests)
                        if (data.result.score_compress < 0) data.result.score_compress = 100;
                        this.$store.commit("set_scanner_result", data.result);
                        // if(this.total_rate === 10) {
                        //     axios.post(MAIL_URL, {
                        //         site: this.$store.state.scanner.site,
                        //         email: this.$store.state.scanner.email,
                        //         screenshot: this.screenshot,
                        //         html_value: this.domContentLoaded,
                        //         requests_value: this.$store.state.scanner.result.requests,
                        //         full_time: this.full_time,
                        //         size_value: this.full_size_string,
                        //         current_url: document.location.href,
                        //         domSize: this.$store.state.scanner.result.domSize,
                        //         rate: this.rate,
                        //         score_compress: this.score_compress,
                        //         score_zip: this.score_zip,
                        //         score_cache: this.score_cache,
                        //         FID: this.FID,
                        //         FCP: this.FCP,
                        //         offscreen_image: this.offscreen_image,
                        //         TTFB: this.$store.state.scanner.result.TTFB,
                        //         LastInteractive: this.$store.state.scanner.result.LastInteractive,
                        //         EvaluateScript: this.$store.state.scanner.result.EvaluateScript,
                        //         total_rate: this.total_rate,
                        //         CMS: this.$store.state.scanner.result.CMS,
                        //         server: this.$store.state.scanner.server,
                        //         client_id: this.$cookies.get("utmstat_client_id"),
                        //     });
                        // }
                        return true;
                    }
                }).catch(error => {
                    this.close();
                    console.error(error)
                })
            }
        },
        wait_result(test_id,server) {
            this.$store.commit("set_scanner_status", "wait");
            this.$store.commit("set_scanner_id", test_id);
            this.$store.commit("set_scanner_server", server);
            this.get_test_result(test_id,server);
        },
        start() {
            this.float.percents = 0;

            this.$sendGoal("check-speed");

            this.textInterval = setInterval(() => {
                this.current_text_index++;
                if (this.current_text_index >= this.analysis.length) this.current_text_index = 0;
                this.float.text = this.analysis[this.current_text_index];
            }, 3000);
            this.horrorInterval = setInterval(() => {
                this.current_text_index++;
                if (this.current_text_index >= this.horrorTextList.length - 1) {
                    this.current_text_index = 0;
                }
                this.horror_opacity = 0;
                return setTimeout(() => {
                    this.horror_opacity = 1;
                    this.horror_text = this.horrorTextList[this.current_text_index];
                }, 300)
            }, 7000);

            this.stopTest();

            this.$store.commit("set_scanner_status", "wait");
            if(this.$store.state.scanner.server.length < 1){
                this.$store.commit("set_scanner_server", 'main');
            }
            return axios.post(API_URL, {url: this.$store.state.scanner.site, server: this.$store.state.scanner.server}).then(({data}) => {
                // this.makeScreen = false;
                /** @namespace data.testID **/
                if (data && data.testID) {
                    setTimeout(this.go_to_scan, 50);
                    this.$cookies.set("current_site_test", JSON.stringify({
                        site: this.$store.state.scanner.site,
                        email: this.$store.state.scanner.email,
                        id: data.testID
                    }), {maxAge: 7 * 24 * 3600});
                    return this.wait_result(data.testID,this.$store.state.scanner.server);
                }
                return console.error(data.error);
            }).catch(console.error)
        },
        grade(value, min, max) {
            let rate = 10;
            if (+value >= max) {
                rate = 0;
            } else {
                if (+value <= min) {
                    rate = 10;
                } else {
                    rate = Math.floor((1 - (+value - min) / (max - min)) * 10)
                }
            }
            return rate
        },
        open_social(href) {
            window.open(href, "", "width=600,height=600");
        },
        submit() {
            this.$store.commit("set_scanner_status", "success");
            this.$sendGoal("lead-after-check");
            // if(this.total_rate === 10) {
            //     return axios.post(MAIL_URL, {
            //         site: this.$store.state.scanner.site,
            //         email: this.$store.state.scanner.email,
            //         screenshot: this.screenshot,
            //         html_value: this.domContentLoaded,
            //         requests_value: this.$store.state.scanner.result.requests,
            //         full_time: this.full_time,
            //         size_value: this.full_size_string,
            //         current_url: document.location.href,
            //         rate: this.rate,
            //         score_compress: this.score_compress,
            //         score_zip: this.score_zip,
            //         score_cache: this.score_cache,
            //         FID: this.FID,
            //         FCP: this.FCP,
            //         offscreen_image: this.offscreen_image,
            //         TTFB: this.$store.state.scanner.result.TTFB,
            //         EvaluateScript: this.$store.state.scanner.result.EvaluateScript,
            //         LastInteractive: this.$store.state.scanner.result.LastInteractive,
            //         total_rate: this.total_rate,
            //         phone: this.phone,
            //         CMS: this.$store.state.scanner.result.CMS,
            //         client_id: this.$cookies.get("utmstat_client_id"),
            //     });
            // } else {
            //     return true;
            // }
          return true;
        },
        close() {
            this.$cookies.remove("current_site_test");
            this.$store.commit('set_scanner_status', null);
            this.$router.push({query: {}})
        },
        stopTest() {
            this.$store.commit('set_scanner_status', null);
            this.startTime = 0;
            clearTimeout(this.testTimeout)
        },
        loadTestData() {
            /** @namespace this.$route **/
            if (this.$route.query.test_id) {
                if (this.$route.query.server) {
                    this.$store.commit("set_scanner_server", this.$route.query.server);
                } else {
                    this.$store.commit("set_scanner_server", 'main');
                }
                this.wait_result(this.$route.query.test_id,this.$store.state.scanner.server);
                return true;
            }
            if (this.$route.query.scan_url) {
                if(this.$store.state.scanner.server && this.$store.state.scanner.server.length > 1){
                    this.$store.commit("set_scanner_server", this.$route.query.server);
                } else {
                    this.$store.commit("set_scanner_server", 'main');
                }
                this.$store.commit("set_scanner_site", this.$route.query.scan_url);
                this.$store.commit("set_scanner_status", "wait");
                return setTimeout(this.start, 300);
            }
        }
    },
    mounted() {
        this.stopTest();
        this.floatInterval = setInterval(() => {
            if (this.$store.state.scanner.status) {
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                const offset = elementOffset(this.$el);
                if (scrollTop + document.documentElement.clientHeight <= offset.top+200) {
                    this.float.show = true;
                    this.float.top = "auto";
                    this.float.bottom = 0;
                } else if (scrollTop >= offset.top + this.$el.offsetHeight - 40) {
                    this.float.show = true;
                    this.float.top = 0;
                    this.float.bottom = "auto";
                } else {
                    this.float.show = false
                }
            }
        }, 50);

        this.loadTestData()
    },
    beforeDestroy() {
        clearInterval(this.floatInterval);
        clearInterval(this.textInterval);
        clearInterval(this.horrorInterval);
        this.stopTest();
    },
    data() {
        return {
            phone: "",
            float: {
                show: true,
                top: 0,
                bottom: "auto",
                text: "Пожалуйста, подождите...",
                percents: 0
            },
            // makeScreen: false,
            current_text_index: 0,
            floatInterval: null,
            textInterval: null,
            percentsInterval: null,
            startTime: 0,
            testTimeout: null,
            horror_text: "до 88% клиентов не вернутся на медленный сайт",
            horrorInterval: null,
            current_horror_index: 0,
            horror_opacity: 1,
            horrorTextList: [
              "До 88% клиентов не вернутся на медленный сайт.",
              "1 секунда замедления стоит $1.6  миллиарда в год для Amazon.",
              "Быстрые сайты получают на 20% больше органического трафика.",
              "Быстрые сайты получают больше просмотров страниц на 14%.",
              "Быстрые сайты дают от 7% до 12% конверсий больше, чем медленные."
            ],
            "analysis": [
                "Идет проверка ответа сервера...",
                "Идет проверка скорости загрузки HTML...",
                "Идет проверка скорости загрузки мобильной версии...",
                "Идет проверка сжатия изображений...",
                "Идет проверка скорости загрузки стилей...",
                "Идет проверка скорости выполнения JavaScript...",
                "Идет проверка скорости отрисовки страницы...",
                "Идет проверка объема страницы...",
                "Идет подсчет числа запросов к серверу...",
                "Идет генерация отчета..."
            ],
            moreDetail: true,
            resourcesDetail: true,
            hint_full: false,
            hint_request: false,
            hint_size: false,
            hint_ttfb: false,
            hint_html: false,
            hint_fcp: false,
            hint_fid: false,
            hint_js: false,
            hint_connect: false,
            hint_interactive: false,
            hint_lazy: false,
            getAudit: false,
            jsDetail: false,
            callback: false,
            subscr: false,
            seo: false,
            queue: 0
        }
    }
}
