//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: "RatingListFilter",
  props: {
    categories: {
      type: Array,
      default: []
    },
  },

  data() {
    return {
      isOpenCategoriesList: false,
      isActiveAllCountries: true,
      categoriesList: [],
      countriesList: [],
    }
  },

  mounted() {
    try {
      document.addEventListener("click", this.outsideListener)
    } catch (e) {
      console.log(e);
    }
    this.categoriesList = this.categories.map(i => ({
      id: i._id,
      label: i.rating.category,
      value: i.rating.category,
      selected: false
    }))

    const uniqCountries = [...new Set([...this.categories.map(i => i.country)])];

    this.countriesList = uniqCountries.map(i => ({value: i, selected: false}))
  },

  beforeDestroy() {
    try {
      document.removeEventListener("click", this.outsideListener)
    } catch (e) {
      console.log(e);
    }
  },

  computed: {
    selectedCountriesList() {
      return this.countriesList.reduce((acc, currentValue) => {
        if (currentValue.selected) acc.push(currentValue.value)
        return acc
      }, [])
    },
    selectedCategoriesList() {
      return this.categoriesList.reduce((acc, currentValue) => {
        if (currentValue.selected) acc.push(currentValue.value)
        return acc
      }, [])
    },
  },
  methods: {
    outsideListener() {
      this.isOpenCategoriesList = false
    },
    isOpenCategoriesListToggle() {
      this.isOpenCategoriesList = !this.isOpenCategoriesList
    },

    handleAllCountries() {
      this.isActiveAllCountries = true
      this.countriesList = this.countriesList.map((i) => {
        return {
          ...i,
          selected: false
        }
      })

      this.$emit('handleAllCountries', this.selectedCategoriesList)
    },

    setCountry(item) {
      this.countriesList = this.countriesList.map((i) => {
        return {
          ...i,
          selected: i.value === item.value ? !item.selected : i.selected
        }
      })
      this.isActiveAllCountries = !this.countriesList.some(i => i.selected)

      this.changeFilters()
    },

    setSelectedItems(item) {
      this.categoriesList = this.categoriesList.map(i => {

        return {
          ...i,
          selected: i.id === item.id ? !item.selected : i.selected
        }
      })

      this.changeFilters()
    },

    changeFilters() {
      this.$emit('changeFilters', this.selectedCountriesList, this.selectedCategoriesList)
    }
  },
}
