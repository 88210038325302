//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ThePopup from "~/components/ThePopup";
import Fz from "~/components/fz";
import FormButton from "~/components/FormButton";
import SmartCaptcha from "@/components/SmartCaptcha/SmartCaptcha.vue";

export default {
  name: "SubscribePopup",
  props: {
    title: {
      type: String,
      default: "Записаться на прямой эфир"
    },
    subject: {
      type: String
    },
    button_label: {
      type: String,
      default: "Записаться"
    },
    timeout: {
      type: Number,
      default: 1500
    },
    default_show: Boolean
  },
  components: {
    SmartCaptcha,
    FormButton,
    Fz,
    ThePopup
  },
  data() {
    return {
      isErrorCaptcha: false,
      show: false,
      email: "",
      name: '',
      comment: '',
      hosting: '',
      role: '',
      phone: '',
      smartToken: '',
      success: false,
      loading: false,
      nosend: false,
      site: this.$store.state.scanner.site
    }
  },

  methods: {
    changeRole(current) {
      if (current && current.value && current.value === "Это не мой сайт") {
        this.nosend = true;
      } else {
        this.nosend = false;
      }
    },
    setSmartToken(response) {
      this.smartToken = response
    },
    submit() {
      let TRACE = null;
      if (typeof b24Tracker !== 'undefined') {
        TRACE = b24Tracker.guest.getTrace();
      }
      if (!this.smartToken) {
        if(!this.isErrorCaptcha) this.isErrorCaptcha = true
        return
      }
      if (this.email) {
        this.loading = true;
        this.$sendGoal("subscribe");
        /** @namespace this.$store.dispatch **/
        this.$store.dispatch("SEND_FORM", {
          action: "/subscribe",
          data: {
            email: this.email,
            addressbook: "89052579",
            name: this.name,
            comment: this.comment,
            phone: this.phone,
            role: this.role,
            subject: this.subject,
            hosting: this.hosting,
            test_id: 'https://loading.express/?test_id=' + this.$store.state.scanner.id + '&server=' + this.$store.state.scanner.server,
            url: this.site,
            client_id: this.$cookies.get("utmstat_client_id"),
            TRACE: JSON.parse(TRACE),
            smartToken: this.smartToken
          }
        }).then(() => {
        }).catch(console.error)
        this.$store.dispatch("SEND_FORM", {
          action: "/subscribe",
          data: {email: this.email, addressbook: "1922766"}
        }).then(() => {
          this.email = "";
          this.success = true;
          this.loading = false;
          this.$store.commit("set_cookie_already_subscribed", {status: true, app: this});
          this.isErrorCaptcha = false
        }).catch(console.error)
      }
    },
    close() {
      this.show = false
    }
  },
  mounted() {
    setTimeout(function () {
      if (document.querySelector("#subscribeFormRoleSelect")) {
        document.querySelector("#subscribeFormRoleSelect").selectedIndex = 0;
      }
    }, 100);
  }
}
