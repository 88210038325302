//
//
//
//
//
//
//
//
//
//
//
//

import TheCheck from "~/components/TheCheck";
import ScanScreen from "~/components/ScanScreen";

export default {
    name: "vkapp",
    components: {
        ScanScreen,
        TheCheck,
    },
    httpHeaders: () => ({
        'Last-Modified': new Date().toGMTString()
    }),
    head() {
        return {
            title: 'Приложение для проверки скорости сайта онлайн. Скорость загрузки сайтов.',
            meta: [
                {hid: "description", name: "description", content: 'Проверить скорость загрузки сайта и страниц. Что влияет на скорость сайта. Как анализировать тесты скорости страниц? Получите бесплатную консультацию!'},
                {hid: "og:locale", property: "og:locale", content: "ru"},
                {hid: "og:title", property: "og:title", content: 'Проверить скорость загрузки сайта онлайн. Скорость загрузки сайтов.'},
                {hid: "og:description", property: "og:description", content: 'Проверить скорость загрузки сайта и страниц. Что влияет на скорость сайта. Как анализировать тесты скорости страниц? Получите бесплатную консультацию!'},
                {hid: "og:image", property: "og:image", content: "https://loading.express/og-ru.jpg"},
                {hid: "twitter:image", property: "twitter:image", content: "https://loading.express/og-ru.jpg"},
                {hid: "twitter:title", property: "twitter:title", content: "Проверить скорость загрузки сайта онлайн. Скорость загрузки сайтов."},
                {hid: "twitter:description", property: "twitter:description", content: "Проверить скорость загрузки сайта и страниц. Что влияет на скорость сайта. Как анализировать тесты скорости страниц? Получите бесплатную консультацию!"},
            ],
            link: [
                {rel: "alternate", hreflang: "en", href: "https://load.express/"}
            ]
        }
    }
}
