import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

import _37c281ce from '../pages/agreement.vue'
import _b39d9398 from '../pages/compare.vue'
import _591c56de from '../pages/cookies.vue'
import _bac7846e from '../pages/our-ip.vue'
import _18611357 from '../pages/privacy.vue'
import _7ec8733b from '../pages/privacy-1.vue'
import _9cf7e34c from '../pages/sub.html.vue'
import _6b4f7a4a from '../pages/vkapp.vue'
import _06e04561 from '../pages/index.vue'
import _0529a419 from '../pages/_slug.vue'

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/agreement",
    component: _37c281ce,
    name: "agreement"
  }, {
    path: "/compare",
    component: _b39d9398,
    name: "compare"
  }, {
    path: "/cookies",
    component: _591c56de,
    name: "cookies"
  }, {
    path: "/our-ip",
    component: _bac7846e,
    name: "our-ip"
  }, {
    path: "/privacy",
    component: _18611357,
    name: "privacy"
  }, {
    path: "/privacy-1",
    component: _7ec8733b,
    name: "privacy-1"
  }, {
    path: "/sub.html",
    component: _9cf7e34c,
    name: "sub.html"
  }, {
    path: "/vkapp",
    component: _6b4f7a4a,
    name: "vkapp"
  }, {
    path: "/",
    component: _06e04561,
    name: "index"
  }, {
    path: "/:slug",
    component: _0529a419,
    name: "slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
