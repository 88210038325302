//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ThePopup from "~/components/ThePopup";
import Fz from "~/components/fz";
import axios from "axios"
import config from "~/config"
import SmartCaptcha from "@/components/SmartCaptcha/SmartCaptcha";

const MAIL_URL = `${config.API}/mail`;

export default {
  name: "callback",
  components: {
    SmartCaptcha,
    Fz,
    ThePopup
  },
  data() {
    return {
      isErrorCaptcha: false,
      form: {
        phone: "",
        name: "",
        email: "",
        site: this.$store.state.scanner.site,
        message: "",
        smartToken: "",
      },
      loading: false,
      success: false
    }
  },
  mounted() {
    if (typeof window !== 'undefined') {
      const traceInput = document.getElementById('FORM_TRACE');

      if (traceInput) {
        traceInput.value = b24Tracker.guest.getTrace();
      }
    }
  },
  methods: {
    setSmartToken(response) {
      this.form.smartToken = response
    },
    submit() {
      let TRACE = null;
      if (typeof b24Tracker !== 'undefined') {
        TRACE = b24Tracker.guest.getTrace();
      }
      if (!this.form.smartToken) {
        if(!this.isErrorCaptcha) this.isErrorCaptcha = true
        return
      }
      return axios.post(MAIL_URL, Object.assign(this.form, {
        mail_subject: "Обратный звонок",
        client_id: this.$cookies.get("utmstat_client_id"),
        current_url: document.location.href,
        TRACE: JSON.parse(TRACE)
      })).then(() => {
        this.success = true;
        this.isErrorCaptcha = false
        this.$sendGoal("lead-callback");
      });
    }
  }
}
