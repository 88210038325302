//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ToolTip from "~/components/ToolTip";
import elementOffset from "~/plugins/elementOffset";

export default {
    name: "TheChampions",
    components: {
        ToolTip
    },
    data() {
        return {
            open: false
        }
    },
    methods: {
        get_category(type="rating", device="mobile", page="main") {
            let list = this.$store.state.data
              .map(item => Object.assign({}, item))
              .filter(item => item.data && item.data[page] && item.data[page][device]);

            list = list.sort((a, b) => {
                let a_value = a.data[page][device][type];
                let b_value = b.data[page][device][type];
                let sort = 0;
                if (a_value > b_value) sort = -1;
                if (a_value < b_value) sort = 1;
                switch (type) {
                    case "html_load_time":
                    case "fully_loaded_time":
                    case "page_bytes":
                    case "page_elements":
                        sort *= -1
                }
                return sort;
            });
            list = list.slice(0, 3);

            for (let i = 0; i < list.length; i++) {
                list[i].url = `http${list[i].data.main[device].ssl ? "s" : ""}://${list[i].url}`;
                list[i] = {
                    score: 3 - i,
                    place: i + 1,
                    type,
                    device,
                    page,
                    item: list[i]
                }
            }

            return list
        },
        get_title(achieve) {
            let title = `${achieve.place} место по `;
            switch (achieve.type) {
                case "rating":
                    title += "рейтингу";
                    break;
                case "fully_loaded_time":
                    title += "скорости загрузки";
                    break;
                case "page_bytes":
                    title += "весу страницы";
                    break;
                case "google_pagespeed":
                    title += "Google PageSpeed Insights";
                    break;
                case "page_elements":
                    title += "числу запросов";
                    break;
            }
            if (achieve.type !== "rating") title += ` на ${achieve.device === "desktop" ? "ПК" : "смартофоне"} (${achieve.page === "main" ? "Главная" : this.$store.state.page_data.rating.second_page.title})`;
            return title
        },
        switch_to(achieve) {
            this.$store.commit(`set_mode_${achieve.device}`);
            this.$store.commit(`set_page_${achieve.page}`);
            this.$store.commit(`set_current_${achieve.type}`);
            this.$store.commit("set_sort", "value");
            this.$store.commit("set_order", -1);
            const el = document.getElementById("stat-list-items");
            if (el) {
                const offset = elementOffset(el);
                window.scrollTo(0, offset.top)
            }
        }
    },
    computed: {
        list() {
            let list = this.get_category();

            list.push(...this.get_category("fully_loaded_time"));
            list.push(...this.get_category("page_bytes"));
            list.push(...this.get_category("page_elements"));
            list.push(...this.get_category("google_pagespeed"));

            list.push(...this.get_category("fully_loaded_time", "mobile", "second"));
            list.push(...this.get_category("page_bytes", "mobile", "second"));
            list.push(...this.get_category("page_elements", "mobile", "second"));
            list.push(...this.get_category("google_pagespeed", "mobile", "second"));

            const items = {};

            for (let item of list) {
                if (!items[item.item._id]) items[item.item._id] = {score: 0, item: item.item, achieves: []};
                items[item.item._id].score += item.score;
                items[item.item._id].achieves.push({place: item.place, type: item.type, device: item.device, page: item.page});
            }

            list = Object.keys(items).map(id => items[id]);
            list = list.sort((a, b) => a.score > b.score ? -1 : (a.score < b.score ? 1 : (a.achieves.length > b.achieves.length ? -1 : 1)));

            list = list.slice(0, 3);
            list = list.map(item => {
                item.achieves = item.achieves.sort((a, b) => a.place > b.place ? 1 : -1);
                return item
            });

            return list
        }
    }
}
