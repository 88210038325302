// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/spinner.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".subscribe-form-block{width:100%}.subscribe-form-block .subscribe-text-block{padding:20px;width:100%;text-align:center;max-width:1150px;margin:auto;font-size:22px}.subscribe-form-block .subscribe-text-block .txt{margin-bottom:5px}.subscribe-form-block .subscribe-text-block img{max-width:100%;height:auto;margin-bottom:30px}.subscribe-form-block .subscribe-text-block .ttl{font-size:36px;margin-bottom:30px}.subscribe-form-block .subscribe-text-block a{color:#01a0e2}.subscribe-form-block .subscribe-text-block a.btn{color:#fff}.subscribe-form-block .subscribe-text-block .banner{margin-top:25px;display:flex;flex-wrap:wrap}.subscribe-form-block .subscribe-text-block .banner a{margin:5px}.subscribe-form-block form.form{margin:auto}.subscribe-form-block form.form.loading{position:relative}.subscribe-form-block form.form.loading:before{content:\"\";background:#1b2638;position:absolute;top:0;left:0;width:100%;height:100%;opacity:.7;z-index:1}.subscribe-form-block form.form.loading:after{content:\"\";background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:contain;width:30px;height:30px;top:50%;left:50%;margin:-15px 0 0 -15px;position:absolute;z-index:2;animation:rotate 1s infinite}@media screen and (min-width:720px){.subscribe-form-block .subscribe-text-block .banner{flex-wrap:nowrap}}@keyframes rotate{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
