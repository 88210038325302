//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios"
import config from "~/config"

const API_URL = `${config.API}/api`;

export default {
  name: "compareList",
  data() {
    return {
      compare: [],
      currentTest: null
    }
  },
  mounted() {
    this.startCompare();
  },
  methods: {
    compareDel(id) {
      let compares = this.compare;
      let oldCook = JSON.parse(this.$cookies.get("comp"));
      compares.forEach(function (it) {
        if (it.id === id) {
          compares.splice(compares.indexOf(it), 1);
        }
      });
      oldCook.forEach(function (it) {
        if (it.id === id) {
          oldCook.splice(oldCook.indexOf(it), 1);
        }
      });
      this.$cookies.set("comp", JSON.stringify(oldCook), {maxAge: 365 * 24 * 3600});
      this.compare = compares;
    },
    async startCompare() {
      this.compare = [];
      if (this.$cookies.get("comp")) {
        let compareObject = JSON.parse(this.$cookies.get("comp"));
        // let tmpCompare = [];
        const promises = []
        if (compareObject.length > 0) {
          compareObject.forEach(function (it) {
            promises.push(
                axios.post(API_URL, {id: it.id, server: it.server}).then(({data}) => {
                  let domain = new URL(data.url);
                  return {
                    id: it.id,
                    server: it.server,
                    domain: domain.hostname,
                    tti: data.result.LastInteractive,
                    result: data
                  }
                  // tmpCompare.push({id: it.id, server: it.server, domain: domain.hostname, tti: data.result.LastInteractive, result: data});
                  // tmpCompare.sort((a, b) => a.tti > b.tti ? 1 : -1);
                }));
          });

          try {
            const tmpComp = await Promise.all(promises);
            console.log(tmpComp)
            this.compare = tmpComp.sort((a, b) => a.tti > b.tti ? 1 : -1);
            console.log(this.compare)
          } catch (e) {
            console.error(e);
          }
        }
      }
    },
    size_human(size) {
      let full_size = +size;
      let size_unit = "B";
      if (full_size > 1024) {
        full_size = (full_size / 1024).toFixed(2);
        size_unit = "KB";
      }
      if (full_size > 1024) {
        full_size = (full_size / 1024).toFixed(2);
        size_unit = "MB";
      }
      return `${full_size} ${size_unit}`
    }
  },

}
