//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormButton from "~/components/FormButton";

export default {
    name: "chromeEx",
    components: {
        FormButton
    },
    data() {
        return {
            show: false,
            details: false,
            TimeOut: null
        }
    },
    methods: {
        close() {
            this.show = false;
            this.$cookies.set("chrome-ext", 1, {maxAge: 365 * 24 * 3600})
        }
    },
    mounted() {
        if (process.browser && !navigator.userAgent.includes("Chrome-Lighthouse") && !this.$cookies.get("chrome-ext") && window.chrome && window.chrome.runtime && window.chrome.runtime.sendMessage) {
            this.TimeOut = setTimeout(() => {
                this.show = true
            }, 100);
        }
    }
}
