// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/compare-white.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".compareAdd .compareAddBlock{display:flex;justify-content:space-between;align-items:center}.compareAdd .compareAddBlock .text-compare{margin-left:10px}.compareAdd .compareAddBlock .text-compare a{color:#d52055}.compareAdd .btn{background:#aaa url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%/30px;color:#fff;min-width:50px;min-height:50px;max-width:50px;max-height:50px}.compareAdd .btn.red{background:#d52055 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%/30px}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
