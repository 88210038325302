//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyImg from "~/components/LazyImg"
import Badge from "~/components/Badge.vue";
import RatingListFilter from "~/components/RatingListFilter.vue";

export default {
  name: "TheRatingsList",
  props: {
    exclude: String,
    title: {
      type: String,
      default: "Отраслевые рейтинги скорости загрузки сайтов"
    },
    content: {
      type: String,
      default: "Отраслевые рейтинги скорости загрузки популярных сайтов по различным нишам, данные обновляются каждую неделю или чаще."
    }
  },
  components: {
    RatingListFilter,
    Badge,
    LazyImg
  },
  data() {
    return {
      ratingList: [],
      colors: {
        0: 'rgba(84, 101, 121, 1)',
        1: 'rgba(59, 73, 92, 1)',
        2: 'rgba(16, 91, 129, 1)',
        3: 'rgba(24, 54, 78, 1)'
      }
    }
  },
  mounted() {
    this.ratingList = this.$store.state.ratings_list
  },
  methods: {
    getTimeStamp(d) {
      const date = new Date(d);
      let month = date.getMonth() + 1;
      if (month < 10) month = `0${month}`;
      return `${date.getDate()}.${month}.${date.getFullYear()}`;
    },

    handleAllCountries(categories = []) {
      this.ratingList = categories?.length ? this.$store.state.ratings_list.filter(i => categories.includes(i.rating.category)) : this.$store.state.ratings_list
    },

    changeFilters(countries = [], categories = []) {
      if (countries.length && categories.length) {
        this.ratingList = this.$store.state.ratings_list.filter(i => countries.includes(i.country) && categories.includes(i.rating.category))
      } else {
        if (countries.length) {
          this.ratingList = this.$store.state.ratings_list.filter(i => countries.includes(i.country))
        } else {
          if (categories.length) {
            this.ratingList = this.$store.state.ratings_list.filter(i => categories.includes(i.rating.category))
          } else {
            this.ratingList = this.$store.state.ratings_list
          }
        }
      }
    },

    getBg(index) {
      if (index % 4 === 0) return this.colors[3]
      if (index % 3 === 0) return this.colors[2]
      if (index % 2 === 0) return this.colors[1]

      return this.colors[0]
    }
  }

}
