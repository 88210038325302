//
//
//
//
//


export default {
  name: "Badge",
  props: {
    text: {
      type: String,
      default: ""
    },
    variant: {
      type: String,
      default: 'gray'
    }
  },
  data() {
    return {
      variants: {
        gray: {
          'background-color': 'rgba(34, 34, 34, 0.46)'
        },
        dark: {
          'background-color': 'rgba(34, 34, 34, 0.8)'
        }
      }
    }
  }
}
