//
//
//
//
//
//
//
//
//
//
//
//
//

import FormButton from "~/components/FormButton";
import LazyImg from "~/components/LazyImg";

export default {
    name: "TheYouTube",
    components: {
        LazyImg,
        FormButton
    }
}
