//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ThePopup from "~/components/ThePopup";
import Fz from "~/components/fz";
import axios from "axios"
import config from "~/config"
import SmartCaptcha from "@/components/SmartCaptcha/SmartCaptcha.vue";

const MAIL_URL = `${config.API}/mail`;

export default {
    name: "ResumePopup",
    components: {
        SmartCaptcha,
        Fz,
        ThePopup
    },
    data() {
        return {
            isErrorCaptcha: false,
            form: {
                phone: "",
                name: "",
                message: "",
                smartToken: "",
            },
            loading: false,
            success: false
        }
    },
    methods: {
        setSmartToken(response) {
          this.form.smartToken = response
        },
        submit() {
            if (!this.form.smartToken) {
              if(!this.isErrorCaptcha) this.isErrorCaptcha = true
              return
            }
            this.success = true;
            this.$sendGoal("lead-resume");
            return axios.post(MAIL_URL, Object.assign(this.form, {
                mail_subject: "Резюме",
                client_id: this.$cookies.get("utmstat_client_id"),
                current_url: document.location.href
            })).then(() => {
                this.success = true
              this.isErrorCaptcha = false
            });
        }
    }
}
