//
//
//
//
//
//
//

import TheFooter from '~/components/TheFooter'
import TheStatText from '~/components/TheStatText'
import topPanel from "@/components/topPanel";

export default {
  name: 'our-ip',
  components: {
    TheStatText,
    TheFooter,
    topPanel
  },
  httpHeaders: () => ({
    'Last-Modified': new Date().toGMTString()
  }),
  head() {
    return {
      title: "IP-адреса ботов LOADING.express",
      meta: [
        {hid: "description", name: "description", content: "Откройте доступ для айпи адресов ботов LOADING.express, чтобы выполнить замер"},
        {hid: "og:locale", property: "og:locale", content: "ru"},
        {hid: "og:title", property: "og:title", content: "IP-адреса ботов LOADING.express"},
        {hid: "og:description", property: "og:description", content: "Откройте доступ для айпи адресов ботов LOADING.express, чтобы выполнить замер"},
        {hid: "og:image", property: "og:image", content: "https://loading.express/og-ru.jpg"},
      ]
    }
  },
  mounted() {
    if(process.browser) {
      const cblist = document.querySelectorAll('.ip li');
      cblist.forEach(el => {
        el.addEventListener('click',() => {
          el.querySelector('input').select();
          document.execCommand('copy');
          window.getSelection().removeAllRanges();
          el.classList.add('copied');
          setTimeout(() => {
            el.classList.remove('copied')
          },2000)
        })
      })
    }
  },
  data() {
    return {
      text: `
    <h1>Наши IP-адреса</h1>
    <div class="right">Актуальность: 14.12.2023 г.</div>
    <p>Если вы используете на сайте защиту от DDoS, то добавьте адреса наших ботов в белый список, чтобы иметь возможность производить замеры.</p>
    <ul>
      <li>
        <input type="text" value="94.250.249.227" disabled>
        <span>Москва</span>
      </li>
      <li>
        <input type="text" value="78.24.219.242" disabled>
        <span>Москва</span>
      </li>
      <li>
        <input type="text" value="82.146.35.40" disabled>
        <span>Москва</span>
      </li>
      <li>
        <input type="text" value="92.223.65.29" disabled>
        <span>Санкт-Петербург</span>
      </li>
      <li>
        <input type="text" value="146.185.198.19" disabled>
        <span>Краснодар</span>
      </li>
      <li>
        <input type="text" value="146.185.196.15" disabled>
        <span>Самара</span>
      </li>
      <li>
        <input type="text" value="92.223.109.23" disabled>
        <span>Екатеринбург</span>
      </li>
      <li>
        <input type="text" value="37.195.157.70" disabled>
        <span>Новосибирск</span>
      </li>
      <li>
        <input type="text" value="5.189.201.16" disabled>
        <span>Красноярск</span>
      </li>
      <li>
        <input type="text" value="92.38.189.60" disabled>
        <span>Хабаровск</span>
      </li>
      <li>
        <input type="text" value="5.101.218.114" disabled>
        <span>Владивосток</span>
      </li>
      <li>
        <input type="text" value="185.244.50.134" disabled>
        <span>Минск</span>
      </li>
      <li>
        <input type="text" value="5.101.36.75" disabled>
        <span>Алматы</span>
      </li>
      <li>
        <input type="text" value="176.9.35.143" disabled>
        <span>Фалькенштайн</span>
      </li>
      <li>
        <input type="text" value="68.183.92.177" disabled>
        <span>Бангалор</span>
      </li>
      <li>
        <input type="text" value="149.28.186.236" disabled>
        <span>Сидней</span>
      </li>
      <li>
        <input type="text" value="45.76.204.87" disabled>
        <span>Токио</span>
      </li>
      <li>
        <input type="text" value="178.128.48.205" disabled>
        <span>Сингапур</span>
      </li>
      <li>
        <input type="text" value="159.203.12.20" disabled>
        <span>Торонто</span>
      </li>
      <li>
        <input type="text" value="149.28.43.104" disabled>
        <span>Нью-Йорк</span>
      </li>
      <li>
        <input type="text" value="66.245.192.167" disabled>
        <span>Лондон</span>
      </li>
      <li>
        <input type="text" value="140.82.55.197" disabled>
        <span>Париж</span>
      </li>
      <li>
        <input type="text" value="192.248.182.187" disabled>
        <span>Франкфурт</span>
      </li>
      <li>
        <input type="text" value="199.247.25.2" disabled>
        <span>Амстердам</span>
      </li>
    </ul>
    <p>Со своей стороны мы гарантируем, что наши боты будут заботливо проверять ваш сайт, не вызывая большой нагрузки на сервер и сеть.</p>
`
    }
  }
}
