//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyImg from "~/components/LazyImg";

export default {
  name: "TheFooter",
  components: {
    LazyImg
  },
}
