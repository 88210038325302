//
//
//
//
//

export default {
    name: "TheStatText",
    props: {
        html: String
    }
}
