//
//
//
//
//
//
//

import TheFooter from '~/components/TheFooter'
import TheStatText from '~/components/TheStatText'
import topPanel from "@/components/topPanel";

export default {
  name: 'agreement',
  components: {
    TheStatText,
    TheFooter,
    topPanel
  },
  httpHeaders: () => ({
    'Last-Modified': new Date().toGMTString()
  }),
  head() {
    return {
      title: "Согласие на обработку персональных данных в LOADING.express",
      meta: [
        {hid: "description", name: "description", content: "Ваше согласие на обработку нами ваших персональных данных"},
        {hid: "og:locale", property: "og:locale", content: "ru"},
        {hid: "og:title", property: "og:title", content: "Согласие на обработку персональных данных в LOADING.express"},
        {hid: "og:description", property: "og:description", content: "Ваше согласие на обработку нами ваших персональных данных"},
        {hid: "og:image", property: "og:image", content: "https://loading.express/og-ru.jpg"},
      ]
    }
  },
  data() {
    return {
      text: `
    <h1>Согласие на обработку персональных данных</h1>
    <div class="right">Редакция № 1 от 01.07.2020 г.</div>
    <p>Настоящим подтверждаю, что согласен на передачу своих персональных данных в составе, для лиц и целей, указанных в <a href="/privacy">Политике конфиденциальности</a>. В том числе для их хранения и обработки.</p>
    <p>Согласие может быть отозвано мною в любой момент путем направления письменного уведомления по электронной почте: sale@loading.express.</p>
        `
    }
  }
}
