// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/chrome.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/images/star.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".chrome-popup{display:none}@media screen and (min-width:720px){.chrome-popup{position:fixed;bottom:20px;left:20px;background:#fff;box-shadow:0 20px 50px rgba(0,36,51,.3);padding:20px;display:flex;justify-content:space-between;z-index:100;border-radius:3px;flex-wrap:wrap;align-items:center;width:500px}.chrome-popup .title{width:100%;margin-bottom:5px;font-weight:700;font-size:22px}.chrome-popup p{font-size:16px;margin-top:10px}.chrome-popup .close{position:absolute;right:0;top:0;cursor:pointer;width:40px;height:40px}.chrome-popup .close:after{transform:rotate(-45deg)}.chrome-popup .close:after,.chrome-popup .close:before{content:\"\";width:25px;height:2px;position:absolute;top:20px;right:10px;background:#335766;margin:0 0 0 -6px}.chrome-popup .close:before{transform:rotate(45deg)}.chrome-popup .info{display:flex;align-items:center;margin-bottom:10px}.chrome-popup .info .lg{min-width:64px;min-height:64px;margin-right:10px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%/64px}.chrome-popup .meta{display:flex;width:100%;justify-content:space-between;font-size:16px;align-items:center}.chrome-popup .meta .setup .str{font-weight:700;margin-left:5px}.chrome-popup .meta .rating{display:flex;align-items:center}.chrome-popup .meta .rating .stars{width:75px;height:15px;margin-left:5px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") repeat-x 50%/15px}.chrome-popup .btn{height:40px;width:auto;background:#335766}.chrome-popup .btn.link{background:none;color:#335766}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
