// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/spinner.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".block.stat-block{background:#444 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 20px 20px/20px;color:#fff;position:relative;transition:all .2s ease-in-out}.block.stat-block .progress{width:0;height:5px;background-color:#d52055;bottom:-2px;left:0;position:absolute;border-radius:0 3px 3px 0}.block.stat-block .title{margin-bottom:50px}.block.stat-block .stats{display:flex;justify-content:space-between;flex-wrap:wrap}.block.stat-block .stats .stat{width:100%;margin:20px 0}.block.stat-block .stats .stat .ttl{font-size:36px;font-weight:700}.block.stat-block.loading{background:#222;background-position:20px 20px;background-size:20px}.block.stat-block.loading .progress{width:100%;transition:all 59.5s linear}.block.stat-block .wrapper{width:100%}@media screen and (min-width:720px){.block.stat-block .stats{flex-wrap:nowrap}.block.stat-block .stats .stat{width:auto;margin:0 20px}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
