//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        src: {
            type: String,
            required: true
        },
        srcset: {
            type: String
        },
        width: {
            type: String
        },
        height: {
            type: String
        },
        alt: {
            type: String,
            default: ""
        }
    },
    data: () => ({ observer: null, intersected: false }),
    computed: {
        srcImage() {
            return this.intersected ? this.src : '';
        },
        srcSetImage() {
            return this.intersected ? this.srcset : '';
        }
    },
    mounted() {
        if ('IntersectionObserver' in window) {
            this.observer = new IntersectionObserver(entries => {
                const image = entries[0];
                if (image.isIntersecting) {
                    this.intersected = true;
                    this.observer.disconnect();
                }
            },{
                rootMargin: "0px 0px 500px 0px"
            });
            this.observer.observe(this.$el);
        } else {
            this.intersected = true;
        }
    },
}
