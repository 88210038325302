//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
import config from "~/config";
import ThePopup from "~/components/ThePopup";
const API_URL = `${config.API}/api`;
const MAIL_URL = `${config.API}/mail`;

export default {
    name: "FormWrapper",
    props: {
        action: String,
    },
    components: {
        ThePopup
    },
    methods: {
        submit(form) {
            const site_input = form.querySelector("input[name=\"site\"]");
            const email_input = form.querySelector("input[name=\"email\"]");
            const server_input = form.querySelector(".ct[name=\"server\"]");
            if(this.show_email && (!email_input || !email_input.value || email_input.value.length < 5)){
                this.error_email = true;
                return false;
            }
            this.isLoad = true;

            if(site_input && site_input.value) {
                axios.post(API_URL, {ping: site_input.value, server: server_input.value}).then(({data}) => {
                    this.isLoad = false;
                    if(data.ping === true){
                        this.$store.commit("set_scanner_site", site_input ? site_input.value : null);
                        this.$store.commit("set_scanner_server", server_input ? server_input.value : null);
                        this.$store.commit("set_scanner_email", email_input ? email_input.value : null);
                        if(this.$store.state.scanner.server === 'main' || this.$store.state.scanner.server.length < 1){
                            this.$cookies.set("server", 'main', {maxAge: 7 * 24 * 3600});
                        }
                        site_input.value = "";

                        this.$store.commit("set_scanner_status", "start");
                        if (this.$store.state.scanner.email) {
                            this.$sendGoal("subscribe");
                            this.$store.dispatch("SEND_FORM", {action: "/subscribe", data: {email: this.$store.state.scanner.email, addressbook: "1922766"}}).then(() => {
                                this.$store.commit("set_cookie_already_subscribed", {status: true, app: this});
                            }).catch(console.error);
                        } else {
                            this.$store.commit("set_cookie_day_scans", {count: this.$store.state.cookie.day_scans + 1, app: this});
                        }
                    } else {
                        axios.post(MAIL_URL, {
                            site: site_input.value,
                            mail_subject: 'Не удалось начать сканирование ' + site_input.value+ ' #ERROR',
                            comment: 'Не удалось начать сканирование ' + site_input.value + ' ' + JSON.stringify(data)
                        });
                        this.error_url = true;
                        return false
                    }
                });
            }
        },
    },
    computed: {
        show_email() {
            return this.$store.state.cookie.day_scans > 1 && !this.$store.state.cookie.already_subscribed
        }
    },
    data() {
        return {
            error_url: false,
            isLoad: false,
            error_email: false
        }
    }
}
