//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormWrapper from "~/components/FormWrapper";
import FormButton from "~/components/FormButton";
import Fz from "~/components/fz";
// import ThePopup from "~/components/ThePopup";
// import selectCity from "~/components/selectCity";

export default {
  name: "TheCheck",
  components: {
    Fz,
    FormWrapper,
    FormButton,
    // ThePopup,
    // selectCity
  },
  mounted() {
    for (let input of this.$el.getElementsByTagName("input")) {
      input.setAttribute("autocorrect", "off")
    }
    if (process.browser) {
      this.changeCountry(document.querySelector(".ct"))
    }
  },
  methods: {
    changeCountry(server) {
      if (server && server.value) {
        if (server.value === 'alm-1') {
          this.country = 'Казахстана';
        } else if (server.value === 'blr-1') {
          this.country = 'Индии';
        } else if (server.value === 'syd-1') {
          this.country = 'Австралии';
        } else if (server.value === 'tyo-1') {
          this.country = 'Японии';
        } else if (server.value === 'sgp-1') {
          this.country = 'Сингапура';
        } else if (server.value === 'tor-1') {
          this.country = 'Канады';
        } else if (server.value === 'nyc-1') {
          this.country = 'США';
        } else if (server.value === 'lon-1') {
          this.country = 'Великобритании';
        } else if (server.value === 'par-1') {
          this.country = 'Франции';
        } else if (server.value === 'fra-1') {
          this.country = 'Германии';
        } else if (server.value === 'ams-1') {
          this.country = 'Нидерландов';
        } else if (server.value === 'mns-1') {
          this.country = 'Беларуси';
        } else {
          this.country = 'России';
        }
        if (server.value !== '') {
          this.$cookies.set("server", server.value, {maxAge: 7 * 24 * 3600});
        }
      }
    }
  },
  computed: {
    show_email() {
      return this.$store.state.cookie.day_scans > 1 && !this.$store.state.cookie.already_subscribed
    }//,
    // show_popup() {
    //     return this.$store.state.cookie.day_scans > 1 && !this.$store.state.cookie.already_subscribed && !this.showed_popup
    // }
  },
  data() {
    return {
      /*
      horrorTextList: [
          "До 88% клиентов не вернутся на медленный сайт.",
          "1 секунда замедления стоит $1.6 миллиарда в год для Amazon.",
          "Быстрые сайты получают на 20% больше органического трафика.",
          "Быстрые сайты получают больше просмотров страниц на 14%.",
          "Быстрые сайты дают от 7% до 12% конверсий больше, чем медленные."
      ],
      */
      country: 'России',
      horror: ''
    }
  }
}
