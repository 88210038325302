//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ThePopup from "~/components/ThePopup";
import Fz from "~/components/fz";
import axios from "axios"
import config from "~/config"

const MAIL_URL = `${config.API}/mail`;

export default {
    name: "MonitoringPopup",
    components: {
        Fz,
        ThePopup
    },
    data() {
        return {
            form: {
                phone: "",
                name: "",
                message: "",
                url: "",
                mail: ""
            },
            loading: false,
            success: false
        }
    },
    methods: {
        submit() {
            this.success = true;
            this.$sendGoal("lead-grow-position");
            return axios.post(MAIL_URL, Object.assign(this.form, {
                mail_subject: "Заявка на подключение мониторинга",
                client_id: this.$cookies.get("utmstat_client_id"),
                current_url: document.location.href
            })).then(() => {
                this.success = true
            });
        }
    }
}
