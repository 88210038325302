//
//
//
//
//
//
//
//
//

export default {
  name: "SmartCaptcha",
  props: {
    containerId: {
      type: String,
      default: "captcha-container"
    },
    isError: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      widgetId: null,
      observer: null,
    }
  },
  mounted() {
    const captchaContainer = document.getElementById(this.containerId);

    if (window.smartCaptcha !== 'undefined') {
      this.widgetId = window.smartCaptcha.render(captchaContainer, {
        sitekey: 'ysc1_lCjp6NstducG1dN7W1fQ3LudhYMFBFD1sC5kYELrfbfeffe3',
        hl: 'ru',
      });
      const token = captchaContainer.querySelector('input[name="smart-token"]');

      this.observer = new MutationObserver((mutations) => {
        for (const mutation of mutations) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'value') {
            const newValue = token.value;
            this.$emit('setSmartToken', newValue);
          }
        }
      });

      this.observer.observe(token, {attributes: true});
    }
  },
  destroyed() {
    if (this.observer) this.observer.disconnect();
  }
}
