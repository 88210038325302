export default function elementOffset(e) {
    const w = window;
    const d = document;
    let top, left;
    if (e.getBoundingClientRect) {
        let box = e.getBoundingClientRect(),
            body = d.body,
            docElem = document.documentElement,
            scrollTop = w.pageYOffset || docElem.scrollTop || body.scrollTop,
            scrollLeft = w.pageXOffset || docElem.scrollLeft || body.scrollLeft,
            clientTop = docElem.clientTop || body.clientTop || 0,
            clientLeft = docElem.clientLeft || body.clientLeft || 0;
        top = box.top + scrollTop - clientTop;
        left = box.left + scrollLeft - clientLeft;
        return {top: Math.round(top), left: Math.round(left)};
    } else {
        top = 0;
        left = 0;
        while(e) {
            top = top + parseFloat(e.offsetTop);
            left = left + parseFloat(e.offsetLeft);
            e = e.offsetParent;
        }
        return {top: Math.round(top), left: Math.round(left)};

    }
};
