//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios"
import config from "~/config"

const STAT_URL = `${config.API}/stat`;

export default {
    name: "stat",
    components: {
    },
    data() {
        return {
            todayTests: 0,
            yearTests: 0,
            yearSpeedup: 0,
            yearConsult: 0,
            live: 0,
            loading: false
        }
    },
    methods: {
        declOfNum(number, titles) {
            let cases = [2, 0, 1, 1, 1, 2];
            return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
        }
    },
    mounted() {
        this.todayTests = this.$store.state.statistics.check.today;
        this.yearTests = this.$store.state.statistics.check.all;
        this.yearSpeedup = this.$store.state.statistics.speedup.all;
        this.yearConsult = this.$store.state.statistics.consult.all;
        this.live = this.$store.state.statistics.live.hour;
        if(process.browser && !navigator.userAgent.includes("Chrome-Lighthouse")) {
            setInterval(() => {
                return axios.get(STAT_URL + '?rnd=' + Math.floor(Date.now() / 1000)).then((response) => {
                    this.loading = false;
                    this.todayTests = response.data.check.today;
                    this.yearTests = response.data.check.all;
                    this.yearSpeedup = response.data.speedup.all;
                    this.yearConsult = response.data.consult.all;
                    this.live = response.data.live.hour;
                    setTimeout(() => {
                        this.loading = true;
                    }, 500);
                });
            }, 60000);
            axios.get(STAT_URL + '?rnd=' + Math.floor(Date.now() / 1000)).then((response) => {
                this.todayTests = response.data.check.today;
                this.yearTests = response.data.check.all;
                this.yearSpeedup = response.data.speedup.all;
                this.yearConsult = response.data.consult.all;
                this.live = response.data.live.hour;
            });
            setTimeout(() => {
                this.loading = true;
            }, 500);
        }
    }
}
