//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: "compareAdd",
    data() {
        return {
            compare: [],
            currentTest: null
        }
    },
    computed: {
        inCompare() {
            let yes = false;
            let thisId = this.$store.state.scanner.id;
            this.compare.forEach(function(it){
                if(it.id === thisId){
                    yes = true;
                }
            });
            return yes;
        },
    },
    methods: {
        compareAdd() {
            this.compare.push(this.currentTest);
            this.$cookies.set("comp", JSON.stringify(this.compare), {maxAge: 365 * 24 * 3600});
        },
        compareDel() {
            let compares = JSON.parse(this.$cookies.get("comp"));
            let thisId = this.$store.state.scanner.id;
            compares.forEach(function(it){
                if(it.id === thisId){
                    compares.splice(compares.indexOf(it),1);
                }
            });
            this.compare = compares;
            this.$cookies.set("comp", JSON.stringify(compares), {maxAge: 365 * 24 * 3600});
        },
    },
    mounted() {
        this.currentTest = {
            id: this.$store.state.scanner.id,
            server: this.$store.state.scanner.server
        };
        setInterval(() => {
            if (this.$cookies.get("comp")) {
                this.compare = JSON.parse(this.$cookies.get("comp"));
            }
        },1000);
    }
}
