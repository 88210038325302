// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/vk.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/images/fb.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/images/tg.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../assets/images/youtube.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "footer{text-align:center;padding:20px 10px}footer.black{background:#00121a;color:#fff}footer.white{background:#fff;color:#00121a}footer img{width:120px;margin-bottom:10px;height:auto}footer .l-loaded{background:none}footer .text{font-size:14px}footer a{color:#d52055;text-decoration:none}footer .docs a:first-child{margin-left:0}footer .social{margin-top:10px;display:flex;align-items:center;justify-content:center}footer .social a{margin:5px;border-radius:3px;width:40px;height:40px;display:block;background-repeat:no-repeat;background-position:50%;font-size:0}footer .social a.vk{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:20px;background-color:#45668e}footer .social a.fb{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:10px;background-color:#3b5998}footer .social a.tg{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-size:20px;background-color:#08c}footer .social a.yt{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");background-size:20px;background-color:red}@media screen and (min-width:1020px){footer{padding:20px}footer .wrapper{display:flex;align-items:center}footer img{width:146px;margin:0 20px 0 0}footer .text{width:100%;line-height:20px;text-align:left}footer .text a:hover{text-decoration:underline}footer .social{justify-content:flex-end;margin-top:0}footer .social a:hover{opacity:.7}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
