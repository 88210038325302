//
//
//
//
//
//
//

import TheFooter from "~/components/TheFooter"
import SubscribeForm from "~/components/SubscribeForm";

export default {
    components: {
        SubscribeForm,
        TheFooter
    },
    httpHeaders: () => ({
        'Last-Modified': new Date().toGMTString()
    }),
    head() {
        return {
            title: "Запишитесь на вебинар по ускорению сайта",
            meta: [
                {hid: "description", name: "description", content: "Узнайте в прямом эфире, почему ваш сайт тормозит и чем опасны медленные сайты"},
                {hid: "og:locale", property: "og:locale", content: "ru"},
                {hid: "og:title", property: "og:title", content: "Запишитесь на вебинар по ускорению сайта"},
                {hid: "og:description", property: "og:description", content: "Узнайте в прямом эфире, почему ваш сайт тормозит и чем опасны медленные сайты"},
                {hid: "og:image", property: "og:image", content: "https://loading.express/og-ru.jpg"},
            ]
        }
    }
}
