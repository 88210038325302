//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";

export default {
    props: {
        url: {
            type: String,
            required: true
        }
    },
    data(){
        return {
            wvData: null
        };
    },
    computed: { },
    mounted() {
        axios.post('https://chromeuxreport.googleapis.com/v1/records:queryRecord?key=AIzaSyBd72Td6DDByzP3U5WK28mg3eVXB7jxM10', {origin: this.url, formFactor: 'PHONE'}).then(({data}) => {
            this.wvData = data.record.metrics;
        }).catch(error => {
            console.log('crux dosen\'t collected: '+error);
        });
    }
}
