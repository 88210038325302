import Vue from "vue"
import config from "~/config"

function static_path(name, size, crop=false) {
    let path = `${config.API}/`;
    if (size) {
        path += `thumb/${crop ? "c" : "r"}/${size.join("x")}/`
    } else {
        path += "static/"
    }
    return `${path}${name}`
}

Vue.prototype.static_path = static_path;

Vue.prototype.$sendGoal = goal => {
    /** @namespace yaCounter.reachGoal **/
    setTimeout(function(){
        if (window.yaCounter50208046) yaCounter50208046.reachGoal(goal);
        dataLayer.push({'event': goal});
    },600);
};
