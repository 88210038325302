//
//
//
//
//
//
//
//


export default {
    name: "compareWidget",
    data() {
        return {
            compare: [],
        }
    },
    methods: {
        declOfNum(number, titles) {
            let cases = [2, 0, 1, 1, 1, 2];
            return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
        }
    },
    mounted() {
        setInterval(() => {
            if (this.$cookies.get("comp")) {
                this.compare = JSON.parse(this.$cookies.get("comp"));
            }
        },1000);
    }
}
