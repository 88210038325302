//
//
//
//
//
//
//
//
//

import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock"

export default {
    name: "ThePopup",
    mounted() {
        disableBodyScroll(this.$el);
    },
    beforeDestroy() {
        enableBodyScroll(this.$el)
    }
}
