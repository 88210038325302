//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Burger from "~/components/Burger/Burger.vue";
import callback from "~/components/callback";
import elementOffset from "~/plugins/elementOffset";
import throttle from 'lodash.throttle';

export default {
  name: "topPanel",
  props: {
    inner: Boolean
  },
  components: {
    Burger,
    callback,
  },
  data() {
    return {
      callb: false,
      isBurgerOpen: false,
      observer: null,
      body: null
    }
  },
  mounted() {
    if (typeof window !== "undefined") {
      this.body = document.body

      this.observer = new ResizeObserver(throttle(([body]) => {
        const bound = body.target.getBoundingClientRect()
        if (bound.width > 720) this.burgerMenuClose()
      }, 1000))

      this.observer.observe(this.body)
    }
  },
  destroyed() {
    this.observer.unobserve(this.body)
  },
  methods: {
    goTo(id) {
      window.scrollTo(0, elementOffset(document.getElementById(id)).top - 40)
    },
    clb() {
      this.callb = true;
      this.$sendGoal("click-callback");
    },
    burgerMenuToggle() {
      this.isBurgerOpen = !this.isBurgerOpen;
      if (typeof window !== "undefined") {
        if (this.isBurgerOpen) {
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.overflow = 'auto'
        }
      }
    },
    burgerMenuClose() {
      this.isBurgerOpen = false
      document.body.style.overflow = 'auto'
    }
  }
}
