//
//
//
//
//
//
//

import TheFooter from '~/components/TheFooter'
import TheStatText from '~/components/TheStatText'
import topPanel from "@/components/topPanel";

export default {
    name: 'cookies',
    components: {
        TheStatText,
        TheFooter,
        topPanel
    },
    httpHeaders: () => ({
        'Last-Modified': new Date().toGMTString()
    }),
  head() {
    return {
      title: "Использование Cookies в LOADING.express",
      meta: [
        {hid: "description", name: "description", content: "Информация о том, как в LOADING.express используются файлы Cookies"},
        {hid: "og:locale", property: "og:locale", content: "ru"},
        {hid: "og:title", property: "og:title", content: "Использование Cookies в LOADING.express"},
        {hid: "og:description", property: "og:description", content: "Информация о том, как в LOADING.express используются файлы Cookies"},
        {hid: "og:image", property: "og:image", content: "https://loading.express/og-ru.jpg"},
      ]
    }
  },
    data() {
      return {
        text: `
      <h1>Мы используем куки!</h1>
      <p>Loading.express, как и большинство сайтов в интернете, использует cookie: это дает нам возможность
        предоставлять пользователям свои услуги, а также анализировать данные и понимать,
        что мы делаем не так и куда развивать наши информационные ресурсы и услуги.</p>
          <h2>Что такое cookie?</h2>
          <p>Cookie — это фрагмент данных сайта, на который вы зашли и который сохраняется на вашем компьютере.</p>
          <h2>Какие cookiе мы используем и с какой целью?</h2>
          <p>Мы используем несколько разных типов cookie:</p>
          <ul>
            <li>Cookie для аутентификации пользователя в чатах: мы запоминаем пользователей, и это дает
            возможность вам не писать всю информацию и себе и свои вопросы каждый раз заново.</li>
            <li>Cookie для статистики: мы используем несколько систем для сбора статистики — нам важно знать,
            как наши пользователи взаимодействуют с нашим сайтом. На основе этих данных мы понимаем размер
            нашей аудитории, а также что мы делаем правильно и что неправильно. Мы осуществляем сбор
            статистики через партнеров — Google Analytics, Yandex Metrika, Hotjar.</li>
            <li>Реклама: мы используем системы показа рекламы (Google Adwords, Yandex.Direct, а также пиксели
            ретаргетинга социальных сетей Вконтакте и Facebook) для того, чтобы показывать вам контекстную
            рекламу, которая бы соответствовала вашим интересам.</li>
          </ul>
          <h2>Что делать, если вы не хотите использовать cookie?</h2>
          <p>Вы можете отключить их в настройках безопасности вашего браузера. Важно понимать, что настройки
          нужно применить во всех браузерах, которыми вы пользуетесь (на компьютере и в телефоне).
          В случае если вы решите отключить cookiе, имейте в виду, что часть функций сервисов loading.express
          перестанут быть вам доступны или могут работать непредсказуемо.</p>
        `
      }
    }
}
