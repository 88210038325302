//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyImg from "~/components/LazyImg";
import FormButton from "~/components/FormButton";
export default {
    name: "SubscribeForm",
    components: {FormButton, LazyImg},
    props: {
        addressbook: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            email: "",
            wait_confirm: false,
            loading: false
        }
    },
    methods: {
        submit() {
            if (this.email) {
                this.loading = true;
                this.$sendGoal("subscribe");
                /** @namespace this.$store.dispatch **/
                this.$store.dispatch("SEND_FORM", {action: "/subscribe", data: {email: this.email, addressbook: this.addressbook}}).then(() => {
                    this.email = "";
                    this.loading = false;
                    this.$store.commit("set_cookie_already_subscribed", {status: true, app: this});
                }).catch(console.error)
            }
        }
    },
    computed: {
        subscribed() {
            return this.$store.state.cookie.already_subscribed
        },
        confirmed() {
            if (this.$route.query.confirm === "yes"  || this.$store.state.cookie.confirmed) {
                this.$store.commit("set_cookie_confirmed", {confirmed: true, app: this});
                this.$store.commit("set_cookie_already_subscribed", {status: true, app: this});
                return true
            }
            return false
        }
    }
}
