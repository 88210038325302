//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import elementOffset from "@/plugins/elementOffset";

export default {
  name: "Burger",
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    inner: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    goTo(id) {
      this.$emit('burgerMenuToggle')
      window.scrollTo(0, elementOffset(document.getElementById(id)).top - 40)
    },
  }
}
